export default [
  {
    path: '/apps/purchaseinboundinvoice/list',
    name: 'apps-purchaseinboundinvoice-list',
    component: () => import('@/views/apps/purchaseinboundinvoice/PurchaseInboundInvoiceList.vue'),
    meta: {
      pageTitle: '采购发票列表',
      breadcrumb: [
        {
          text: '采购发票',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchaseinboundinvoice/edit',
    name: 'apps-purchaseinboundinvoice-edit',
    component: () => import('@/views/apps/purchaseinboundinvoice/PurchaseInboundInvoiceEdit.vue'),
    meta: {
      pageTitle: '采购发票信息',
      breadcrumb: [
        {
          text: '采购发票',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchaseinboundinvoice/view',
    name: 'apps-purchaseinboundinvoice-view',
    component: () => import('@/views/apps/purchaseinboundinvoice/PurchaseInboundInvoiceEdit.vue'),
    meta: {
      pageTitle: '采购发票',
      breadcrumb: [
        {
          text: '采购发票',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
