export default [
  {
    path: '/apps/settlement/receive/sum/list',
    name: 'apps-settlement-receive-sum-list',
    component: () => import('@/views/apps/settlementreceivesum/settlementReceiveSumList.vue'),
    meta: {
      pageTitle: '应收汇总列表',
      breadcrumb: [
        {
          text: '应收汇总列表',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/apps/statement/edit',
  //   name: 'apps-statement-edit',
  //   component: () => import('@/views/apps/statement/StatementEdit.vue'),
  //   meta: {
  //     pageTitle: '信息',
  //     breadcrumb: [
  //       {
  //         text: '线下结算单信息',
  //       },
  //       {
  //         text: '信息',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
