export default [
  {
    path: '/apps/produceordercomplete/list',
    name: 'apps-produceordercomplete-list',
    component: () => import('@/views/apps/produceordercomplete/ProduceOrderCompleteList.vue'),
    meta: {
      pageTitle: '生产单成品列表',
      breadcrumb: [
        {
          text: '生产单成品',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/produceordercomplete/edit',
    name: 'apps-produceordercomplete-edit',
    component: () => import('@/views/apps/produceordercomplete/ProduceOrderCompleteEdit.vue'),
    meta: {
      pageTitle: '生产单成品信息',
      breadcrumb: [
        {
          text: '生产单成品',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/produceordercomplete/view',
    name: 'apps-produceordercomplete-view',
    component: () => import('@/views/apps/produceordercomplete/ProduceOrderCompleteEdit.vue'),
    meta: {
      pageTitle: '生产单成品',
      breadcrumb: [
        {
          text: '生产单成品',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
