export default [
  {
    path: '/apps/warehouserelation/list',
    name: 'apps-warehouserelation-list',
    component: () => import('@/views/apps/warehouserelation/WarehouseRelationList.vue'),
    meta: {
      pageTitle: '外部仓库关联列表',
      breadcrumb: [
        {
          text: '外部仓库关联',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/warehouserelation/edit',
    name: 'apps-warehouserelation-edit',
    component: () => import('@/views/apps/warehouserelation/WarehouseRelationEdit.vue'),
    meta: {
      pageTitle: '外部仓库关联信息',
      breadcrumb: [
        {
          text: '外部仓库关联',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/warehouserelation/view',
    name: 'apps-warehouserelation-view',
    component: () => import('@/views/apps/warehouserelation/WarehouseRelationEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
