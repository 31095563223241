export default [
    {
        path: '/apps/salesorder/list',
        name: 'apps-salesorder-list',
        component: () => import('@/views/apps/salesorder/SalesOrderList.vue'),
        meta: {
            pageTitle: '销售订单列表',
            breadcrumb: [
                {
                    text: '销售订单',
                }, {
                    text: '列表',
                    active: true,
                },
            ],
        },
    }, {
        path: '/apps/salesorder/edit',
        name: 'apps-salesorder-edit',
        component: () => import('@/views/apps/salesorder/SalesOrderEdit.vue'),
        meta: {
            pageTitle: '销售订单信息',
            breadcrumb: [
                {
                    text: '销售订单',
                }, {
                    text: '信息',
                    active: true,
                },
            ],
        },
    }, {
        path: '/apps/salesorder/view',
        name: 'apps-salesorder-view',
        component: () => import('@/views/apps/salesorder/SalesOrderEdit.vue'),
        meta: {
            pageTitle: '销售订单信息',
            breadcrumb: [
                {
                    text: '销售订单',
                }, {
                    text: '信息',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps/presalesorder/list',
        name: 'apps-presalesorder-list',
        component: () => import('@/views/apps/presalesorder/PreSalesOrderList'),
        meta: {
            pageTitle: '销售订货单列表',
            breadcrumb: [
                {
                    text: '销售订货单',
                }, {
                    text: '列表',
                    active: true,
                },
            ],
        },
    }, {
        path: '/apps/presalesorder/edit',
        name: 'apps-presalesorder-edit',
        component: () => import('@/views/apps/presalesorder/PreSalesOrderEdit'),
        meta: {
            pageTitle: '销售订货单信息',
            breadcrumb: [
                {
                    text: '销售订货单',
                }, {
                    text: '信息',
                    active: true,
                },
            ],
        },
    },
]
