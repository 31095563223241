export default [
  {
    path: '/apps/stocklogchart/list',
    name: 'apps-stocklogchart-list',
    component: () => import('@/views/apps/stocklog/StockLogChart/StockLogChartList.vue'),
    meta: {
      pageTitle: '库存日志报表列表',
      breadcrumb: [
        {
          text: '库存日志报表',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },

]
