import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BModal,
  BFormCheckbox,
  BOverlay,
  BTableSimple,
  BBadge,
  BFormTextarea,
  BootstrapVueIcons,
  VBTooltip,
  BAlert,
  BDropdown,
  BDropdownItem,
  BDropdownGroup,
  BSpinner,
  BPagination,
  BPopover,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

const components = {
  FeatherIcon,
  ValidationProvider,
  ValidationObserver,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BForm,
  BRow,
  BBadge,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BModal,
  BFormCheckbox,
  flatPickr,
  BFormTextarea,
  BOverlay,
  vSelect,
  BTableSimple,
  BootstrapVueIcons,
  VBTooltip,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BDropdownGroup,
  BAlert,
  BPagination,
  BPopover
}
Object.keys(components)
  .forEach(key => {
    Vue.component(key, components[key])
  })
/*Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);*/
