export default [
  {
    path: '/apps/feeloadcharge/list',
    name: 'apps-feeloadcharge-list',
    component: () => import('@/views/apps/feeloadcharge/FeeLoadChargeList.vue'),
    meta: {
      pageTitle: '装卸费结算列表',
      breadcrumb: [
        {
          text: '装卸费结算',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
]
