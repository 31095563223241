export default [
  {
    path: '/apps/inventoryorderdetailmodify/list',
    name: 'apps-inventoryorderdetailmodify-list',
    component: () => import('@/views/apps/inventoryorderdetailmodify/InventoryOrderDetailModifyList.vue'),
    meta: {
      pageTitle: '盘点单明细_库存调整申请单列表',
      breadcrumb: [
        {
          text: '盘点单明细_库存调整申请单',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inventoryorderdetailmodify/edit',
    name: 'apps-inventoryorderdetailmodify-edit',
    component: () => import('@/views/apps/inventoryorderdetailmodify/InventoryOrderDetailModifyEdit.vue'),
    meta: {
      pageTitle: '盘点单明细_库存调整申请单信息',
      breadcrumb: [
        {
          text: '盘点单明细_库存调整申请单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inventoryorderdetailmodify/view',
    name: 'apps-inventoryorderdetailmodify-view',
    component: () => import('@/views/apps/inventoryorderdetailmodify/InventoryOrderDetailModifyEdit.vue'),
    meta: {
      pageTitle: '盘点单明细_库存调整申请单',
      breadcrumb: [
        {
          text: '盘点单明细_库存调整申请单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
