export default [
  {
    path: '/apps/salesrefund/list',
    name: 'apps-salesrefund-list',
    component: () => import('@/views/apps/salesrefund/SalesRefundList.vue'),
    meta: {
      pageTitle: '列表',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/salesrefund/edit',
    name: 'apps-salesrefund-edit',
    component: () => import('@/views/apps/salesrefund/SalesRefundEdit.vue'),
    meta: {
      pageTitle: '信息',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/salesrefund/view',
    name: 'apps-salesrefund-view',
    component: () => import('@/views/apps/salesrefund/SalesRefundEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
