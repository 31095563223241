export default [
  {
    path: '/apps/cargodamage/list',
    name: 'apps-cargodamage-list',
    component: () => import('@/views/apps/cargodamage/CargoDamageList.vue'),
    meta: {
      pageTitle: '货损报表列表',
      breadcrumb: [
        {
          text: '货损报表',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/cargodamage/edit',
    name: 'apps-cargodamage-edit',
    component: () => import('@/views/apps/cargodamage/CargoDamageEdit.vue'),
    meta: {
      pageTitle: '货损报表信息',
      breadcrumb: [
        {
          text: '货损报表',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/cargodamage/view',
    name: 'apps-cargodamage-view',
    component: () => import('@/views/apps/cargodamage/CargoDamageEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
