export default [
  {
    path: '/apps/account-setting/list',
    name: 'apps-setting-list',
    component: () => import('@/views/apps/setting/SettingList.vue'),
    meta: {
      pageTitle: '系统设置列表',
      breadcrumb: [
        {
          text: '系统设置',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/account-setting/edit',
    name: 'apps-setting-edit',
    component: () => import('@/views/apps/setting/SettingEdit.vue'),
    meta: {
      pageTitle: '系统设置信息',
      breadcrumb: [
        {
          text: '系统设置',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/account-setting/view',
    name: 'apps-setting-view',
    component: () => import('@/views/apps/setting/SettingEdit.vue'),
    meta: {
      pageTitle: '系统设置',
      breadcrumb: [
        {
          text: '系统设置',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
