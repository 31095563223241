export default [
  {
    path: '/apps/purchasereturnbound/list',
    name: 'apps-purchasereturnbound-list',
    component: () => import('@/views/apps/purchasereturnbound/PurchaseReturnboundList.vue'),
    meta: {
      pageTitle: '采购退货列表',
      breadcrumb: [
        {
          text: '采购退货',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchasereturnbound/edit',
    name: 'apps-purchasereturnbound-edit',
    component: () => import('@/views/apps/purchasereturnbound/PurchaseReturnboundEdit.vue'),
    meta: {
      pageTitle: '采购退货信息',
      breadcrumb: [
        {
          text: '采购退货',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchasereturnbound/view',
    name: 'apps-purchasereturnbound-view',
    component: () => import('@/views/apps/purchasereturnbound/PurchaseReturnboundEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
