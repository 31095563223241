export default [
  {
    path: '/apps/priceactivity/list',
    name: 'apps-priceactivity-list',
    component: () => import('@/views/apps/priceactivity/PriceActivityList.vue'),
    meta: {
      pageTitle: '活动价格列表',
      breadcrumb: [
        {
          text: '活动价格',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/priceactivity/edit',
    name: 'apps-priceactivity-edit',
    component: () => import('@/views/apps/priceactivity/PriceActivityEdit.vue'),
    meta: {
      pageTitle: '活动价格信息',
      breadcrumb: [
        {
          text: '活动价格',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/priceactivity/view',
    name: 'apps-priceactivity-view',
    component: () => import('@/views/apps/priceactivity/PriceActivityEdit.vue'),
    meta: {
      pageTitle: '活动价格',
      breadcrumb: [
        {
          text: '活动价格',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
