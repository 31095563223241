export default [
  {
    path: '/apps/workflow/list',
    name: 'apps-workflow-list',
    component: () => import('@/views/apps/workflow/WorkflowList.vue'),
    meta: {
      pageTitle: '工作流列表',
      breadcrumb: [
        {
          text: '工作流',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/workflow/edit',
    name: 'apps-workflow-edit',
    component: () => import('@/views/apps/workflow/WorkflowEdit.vue'),
    meta: {
      pageTitle: '工作流信息',
      breadcrumb: [
        {
          text: '工作流',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/workflow/view',
    name: 'apps-workflow-view',
    component: () => import('@/views/apps/workflow/WorkflowEdit.vue'),
    meta: {
      pageTitle: '工作流',
      breadcrumb: [
        {
          text: '工作流',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
