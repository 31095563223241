export default [
  {
    path: '/apps/inventoryorder/list',
    name: 'apps-inventoryorder-list',
    component: () => import('@/views/apps/inventoryorder/InventoryOrderList.vue'),
    meta: {
      pageTitle: '库存盘点列表',
      breadcrumb: [
        {
          text: '库存盘点',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inventoryorder/edit',
    name: 'apps-inventoryorder-edit',
    component: () => import('@/views/apps/inventoryorder/InventoryOrderEdit.vue'),
    meta: {
      pageTitle: '库存盘点信息',
      breadcrumb: [
        {
          text: '库存盘点',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inventoryorder/view',
    name: 'apps-inventoryorder-view',
    component: () => import('@/views/apps/inventoryorder/InventoryOrderEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
