export default [
  {
    path: '/apps/datacentersales/list',
    name: 'apps-datacentersales-list',
    component: () => import('@/views/apps/datacentersales/DatacenterSalesList.vue'),
    meta: {
      pageTitle: '场次销售数据列表',
      breadcrumb: [
        {
          text: '场次销售数据',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/datacentersales/edit',
    name: 'apps-datacentersales-edit',
    component: () => import('@/views/apps/datacentersales/DatacenterSalesEdit.vue'),
    meta: {
      pageTitle: '场次销售数据信息',
      breadcrumb: [
        {
          text: '场次销售数据',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/datacentersales/view',
    name: 'apps-datacentersales-view',
    component: () => import('@/views/apps/datacentersales/DatacenterSalesEdit.vue'),
    meta: {
      pageTitle: '场次销售数据',
      breadcrumb: [
        {
          text: '场次销售数据',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {

    path: '/apps/datacentersales/view/modalList',
    name: 'apps-datacentersales-view-modalList',
    component: () => import('@/views/apps/datacentersales/modal/ModalList'),
    meta: {
      pageTitle: '线上结算单',
      breadcrumb: [
        {
          text: '线上结算单',
        },
        {
          text: '新增',
          active: true,
        },
      ],
    },
  },
  {

    path: '/apps/datacentersales/view/offModalList',
    name: 'apps-datacentersales-view-offModalList',
    component: () => import('@/views/apps/datacentersales/off-modal/ModalList'),
    meta: {
      pageTitle: '线下结算单',
      breadcrumb: [
        {
          text: '线下结算单',
        },
        {
          text: '新增',
          active: true,
        },
      ],
    },
  },
]
