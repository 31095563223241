export default [
  {
    path: '/apps/warehousehead/list',
    name: 'apps-warehousehead-list',
    component: () => import('@/views/apps/warehousehead/WarehouseHeadList.vue'),
    meta: {
      pageTitle: '列表',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/warehousehead/edit',
    name: 'apps-warehousehead-edit',
    component: () => import('@/views/apps/warehousehead/WarehouseHeadEdit.vue'),
    meta: {
      pageTitle: '信息',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/warehousehead/view',
    name: 'apps-warehousehead-view',
    component: () => import('@/views/apps/warehousehead/WarehouseHeadEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
