export default [
  {
    path: '/apps/pricefixedruleonlinecategory/list',
    name: 'apps-pricefixedruleonlinecategory-list',
    component: () => import('@/views/apps/pricefixedruleonlinecategory/PriceFixedRuleOnlineCategoryList.vue'),
    meta: {
      pageTitle: '线下定价规则 品类平台列表',
      breadcrumb: [
        {
          text: '线下定价规则 品类平台',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/pricefixedruleonlinecategory/edit',
    name: 'apps-pricefixedruleonlinecategory-edit',
    component: () => import('@/views/apps/pricefixedruleonlinecategory/PriceFixedRuleOnlineCategoryEdit.vue'),
    meta: {
      pageTitle: '线下定价规则 品类平台信息',
      breadcrumb: [
        {
          text: '线下定价规则 品类平台',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/pricefixedruleonlinecategory/view',
    name: 'apps-pricefixedruleonlinecategory-view',
    component: () => import('@/views/apps/pricefixedruleonlinecategory/PriceFixedRuleOnlineCategoryEdit.vue'),
    meta: {
      pageTitle: '线下定价规则 品类平台',
      breadcrumb: [
        {
          text: '线下定价规则 品类平台',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
