export default [
  {
    path: '/apps/purchasestockup/list',
    name: 'apps-purchasestockup-list',
    component: () => import('@/views/apps/purchasestockup/PurchaseStockupList.vue'),
    meta: {
      pageTitle: '备采列表',
      breadcrumb: [
        {
          text: '备采',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchasestockup/edit',
    name: 'apps-purchasestockup-edit',
    component: () => import('@/views/apps/purchasestockup/PurchaseStockupEdit.vue'),
    meta: {
      pageTitle: '信息',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchasestockup/view',
    name: 'apps-purchasestockup-view',
    component: () => import('@/views/apps/purchasestockup/PurchaseStockupEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
