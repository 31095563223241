import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'

import attachment from './routes/apps/attachment'
import brand from './routes/apps/brand'
import category from './routes/apps/category'
import code from './routes/apps/code'
import company from './routes/apps/company'
import purchaseordercycle from './routes/apps/purchaseordercycle'
import autoexecute from './routes/apps/autoexecute'
import autoexecutesetting from './routes/apps/autoexecutesetting'
import onecontractsales from './routes/apps/onecontractsales'
import onecontractpurchase from './routes/apps/onecontractpurchase'
import inbound from './routes/apps/inbound'
import stockoccupied from './routes/apps/stockoccupied'
import invoiceapply from './routes/apps/invoiceapply'
import storecashout from './routes/apps/storecashout'
import purchaseinvoice from './routes/apps/purchaseinvoice'
import purchaseinvoicestatement from './routes/apps/purchaseinvoicestatement'
import contact from './routes/apps/contact'
import contract from './routes/apps/contract'
import department from './routes/apps/department'
import module from './routes/apps/module'
import product from './routes/apps/product'
import role from './routes/apps/role'
import setting from './routes/apps/setting'
import stock from './routes/apps/stock'
import warehouse from './routes/apps/warehouse'
import workflow from './routes/apps/workflow'
import purchaseorder from './routes/apps/purchaseorder'
import purchaseinbound from './routes/apps/purchaseinbound'
import purchaseinbounditem from './routes/apps/purchaseinbounditem'
import salesorder from './routes/apps/salesorder'
import visit from './routes/apps/visit'
import errorinfo from './routes/apps/errorinfo'
import pwd from './routes/apps/pwd'
import user from './routes/apps/user'
import allotoutbound from './routes/apps/allotoutbound'
import feed from './routes/apps/feed'
import notification from './routes/apps/notification'
import allotoutbounditem from '@/router/routes/apps/allotoutbounditem'
import allotoutbounditemproduct from '@/router/routes/apps/allotoutbounditemproduct'
import todo from './routes/apps/todo'
import salesoutbound from '@/router/routes/apps/salesoutbound'
import salesoutbounditem from '@/router/routes/apps/salesoutbounditem'
import stockallotoutbounditem from './routes/apps/stockallotoutbounditem'
import stocklock from './routes/apps/stocklock'
import salesreturnbound from './routes/apps/salesreturnbound'
import salesreturnbounditem from './routes/apps/salesreturnbounditem'
import stocklog from '@/router/routes/apps/stocklog'
import stocklogchart from '@/router/routes/apps/stocklogchart'
import fixedcharge from '@/router/routes/apps/fixedcharge'
import fixedchargeitem from '@/router/routes/apps/fixedchargeitem'
import financeresource from '@/router/routes/apps/financeresource'
import financeresourcerelation from '@/router/routes/apps/financeresourcerelation'
import warehouselocation from '@/router/routes/apps/warehouselocation'
import warehouserelation from '@/router/routes/apps/warehouserelation'
import warehousecharge from '@/router/routes/apps/warehousecharge'
import stockoutbounditem from '@/router/routes/apps/stockoutbounditem'
import bankaccount from '@/router/routes/apps/bankaccount'
import companyprepaid from '@/router/routes/apps/companyprepaid'
import customerService from '@/router/routes/apps/customerservice'
import fee from '@/router/routes/apps/fee'
import store from '@/router/routes/apps/store'

import datacentersales from '@/router/routes/apps/datacentersales'
import datacenterintobound from '@/router/routes/apps/datacenterintobound'
import datacenteroutbound from '@/router/routes/apps/datacenteroutbound'
import inventoryorder from '@/router/routes/apps/inventoryorder'
import moduleStore from '@/views/apps/module/moduleStore'
import request from '@/store'
import { returnmodules } from '@/navigation/vertical/modules'
import inventoryorderdetail from '@/router/routes/apps/inventoryorderdetail'
import appversion from '@/router/routes/apps/appversion'
import purchasepayment from '@/router/routes/apps/purchasepayment'
import purchasepaid from '@/router/routes/apps/purchasepaid'
import stockinbound from '@/router/routes/apps/stockinbound'
import stockinbounditem from '@/router/routes/apps/stockinbounditem'
import serviceorder from '@/router/routes/apps/serviceorder'
import purchaserefund from '@/router/routes/apps/purchaserefund'
import stockrollback from '@/router/routes/apps/stockrollback'
import financepayment from '@/router/routes/apps/financepayment'
import financepaymentitem from '@/router/routes/apps/financepaymentitem'
import inventoryorderdetailextend from '@/router/routes/apps/inventoryorderdetailextend'
import inventoryorderdetailmodify from '@/router/routes/apps/inventoryorderdetailmodify'
import storecontact from "@/router/routes/apps/storecontact";
import produceorder from "@/router/routes/apps/produceorder";
import produceordersource from "@/router/routes/apps/produceordersource";
import produceordercomplete from "@/router/routes/apps/produceordercomplete";
import productlabel from "@/router/routes/apps/productlabel";
import salesorderchecklog from "@/router/routes/apps/salesorderchecklog";
import productbox from "@/router/routes/apps/productbox";
import modifylog from "@/router/routes/apps/modifylog";
import allotoutboundoutbound from "@/router/routes/apps/allotoutboundoutbound";
import allotoutboundoutbounditem from "@/router/routes/apps/allotoutboundoutbounditem";
import salesreport from "@/router/routes/apps/salesreport";
import allotoutboundinbound from "@/router/routes/apps/allotoutboundinbound";
import allotoutboundinbounditem from "@/router/routes/apps/allotoutboundinbounditem";
import otherinbound from '@/router/routes/apps/otherinbound'
import storesales from "@/router/routes/apps/storesales";
import purchasereturnbound from '@/router/routes/apps/purchasereturnbound'
import purchasereturnbounditem from '@/router/routes/apps/purchasereturnbounditem'
import offlinesalesorder from "@/router/routes/apps/offlinesalesorder";
import offlinesalesorderitem from "@/router/routes/apps/offlinesalesorderitem";
import purchaseoutbound from '@/router/routes/apps/purchaseoutbound'
import purchaseoutbounditem from '@/router/routes/apps/purchaseoutbounditem'
import outbound from '@/router/routes/apps/outbound'
import outbounditem from '@/router/routes/apps/outbounditem'
import warehousestatement from "@/router/routes/apps/warehousestatement";
import statement from "@/router/routes/apps/statement";
import statementitem from "@/router/routes/apps/statementitem";
import statementcost from "@/router/routes/apps/statementcost";
import statementreceive from "@/router/routes/apps/statementreceive";
import statementreceiveitem from "@/router/routes/apps/statementreceiveitem";
import salesrefund from "@/router/routes/apps/salesrefund";
import salesrefunditem from "@/router/routes/apps/salesrefunditem";
import operationlimit from "@/router/routes/apps/operationlimit";
import salesoutboundabort from "@/router/routes/apps/salesoutboundabort";
import salesoutboundabortitem from "@/router/routes/apps/salesoutboundabortitem";
import warehousehead from "@/router/routes/apps/warehousehead";
import stockbase from "@/router/routes/apps/stockbase";
import purchaseordermodify from "@/router/routes/apps/purchaseordermodify";
import companyqrcode from "@/router/routes/apps/companyqrcode";
import presalesreturnbound from "@/router/routes/apps/presalesreturnbound";
import presalesreturnbounditem from "@/router/routes/apps/presalesreturnbounditem";
import statementreceivemethod from "@/router/routes/apps/statementreceivemethod";
import statementchange from "@/router/routes/apps/statementchange";
import statementchangeitem from "@/router/routes/apps/statementchangeitem";
import news from "@/router/routes/apps/news";
import purchaseorderchangecost from "@/router/routes/apps/purchaseorderchangecost";
import brandarchive from "@/router/routes/apps/brandarchive";
import brandarchiveresult from "@/router/routes/apps/brandarchiveresult";
import brandreward from "@/router/routes/apps/brandreward";
import brandrewardorder from "@/router/routes/apps/brandrewardorder";
import productrelationidwarehouse from "@/router/routes/apps/productrelationidwarehouse";
import brandrewardlog from "@/router/routes/apps/brandrewardlog";
import stockstatuschange from "@/router/routes/apps/stockstatuschange";
import feeloadsetting from "@/router/routes/apps/feeloadsetting";
import feetransportsetting from "@/router/routes/apps/feetransportsetting";
import stockchangeapply from "@/router/routes/apps/stockchangeapply";
import feeloadcharge from "@/router/routes/apps/feeloadcharge";
import feetransportcharge from "@/router/routes/apps/feetransportcharge";
import bom from "@/router/routes/apps/bom";
import bomproduceorder from "@/router/routes/apps/bomproduceorder";
import note from "@/router/routes/apps/note";
import punishorder from "@/router/routes/apps/punishorder";
import punishorderdeal from "@/router/routes/apps/punishorderdeal";
import productavgprice from "@/router/routes/apps/productavgprice";
import companycheck from "@/router/routes/apps/companycheck";
import settlementReceiveSum from "@/router/routes/apps/settlementReceiveSum";
import logisticsorder from "@/router/routes/apps/logisticsorder";
import logisticscarriager from "@/router/routes/apps/logisticscarriager";
import logisticsfeed from "@/router/routes/apps/logisticsfeed";
import logisticsreport from "@/router/routes/apps/logisticsreport";
import companynew from "@/router/routes/apps/companynew";
import groupbrand from "@/router/routes/apps/groupbrand";
import warehouserentorder from "@/router/routes/apps/warehouserentorder";
import cargodamage from "@/router/routes/apps/cargodamage";
import stockpurchaseteam from "@/router/routes/apps/stockpurchaseteam";
import companycredit from "@/router/routes/apps/companycredit";
import companycredittemp from "@/router/routes/apps/companycredittemp";
import companystorecredit from "@/router/routes/apps/companystorecredit";
import companycreditlog from "@/router/routes/apps/companycreditlog";
import priceactivity from "@/router/routes/apps/priceactivity";
import pricecheck from "@/router/routes/apps/pricecheck";
import pricecheckitem from "@/router/routes/apps/pricecheckitem";
import pricefixedruleoffline from "@/router/routes/apps/pricefixedruleoffline";
import pricefixedruleonlinecategory from "@/router/routes/apps/pricefixedruleonlinecategory";
import pricefixedruleonlinenormal from "@/router/routes/apps/pricefixedruleonlinenormal";
import pricefixedruleonlinespecial from "@/router/routes/apps/pricefixedruleonlinespecial";
import priceguideoffline from "@/router/routes/apps/priceguideoffline";
import priceguideonline from "@/router/routes/apps/priceguideonline";
import priceverifyruleoffline from "@/router/routes/apps/priceverifyruleoffline";
import customerratinglevel from "@/router/routes/apps/customerratinglevel";
import gridhouse from "@/router/routes/apps/gridhouse";
import gridhousedata from "@/router/routes/apps/gridhousedata";
import gridhousearap from "@/router/routes/apps/gridhousearap";
import gridhouseReport from "@/router/routes/apps/gridhouseReport";
import cargoforce from "@/router/routes/apps/cargoforce";
import datacentersalespt from "@/router/routes/apps/datacentersalespt";
import statementproxyverification from "@/router/routes/apps/statementproxyverification";
import statementproxy from "@/router/routes/apps/statementproxy";
import salescost from "@/router/routes/apps/salescost";
import purchasestockup from "@/router/routes/apps/purchasestockup";
import purchasestockupitem from "@/router/routes/apps/purchasestockupitem";
import brandamount from "@/router/routes/apps/brandamount";
import warehousestatementrule from "@/router/routes/apps/warehousestatementrule";
import stockchecksecond from "@/router/routes/apps/stockchecksecond";
import stockcheckhistory from "@/router/routes/apps/stockcheckhistory";
import stockcreate from "@/router/routes/apps/stockcreate";
import salesorderdeposit from "@/router/routes/apps/salesorderdeposit";
import stockstocktaking from "@/router/routes/apps/stockstocktaking";
import stockstocktakingitem from "@/router/routes/apps/stockstocktakingitem";
import purchaseinboundorder from "@/router/routes/apps/purchaseinboundorder";
import purchaseinboundorderitem from "@/router/routes/apps/purchaseinboundorderitem";
import purchaseinboundinvoice from "@/router/routes/apps/purchaseinboundinvoice";
import purchaseclose from "@/router/routes/apps/purchaseclose";

if (!request.hasModule('module')) request.registerModule('module', moduleStore)

Vue.use(VueRouter)


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [
    {
      path: '/',
      redirect: { name: 'dashboard-ecommerce' }
    },
    {
      path: '/apps',
      redirect: { name: 'dashboard-ecommerce' }
    },
    ...apps,
    ...note,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    ...attachment,
    ...brand,
    ...groupbrand,
    ...category,
    ...code,
    ...company,
    ...purchaseordercycle,
    ...autoexecutesetting,
    ...onecontractsales,
    ...onecontractpurchase,
    ...inbound,
    ...autoexecute,
    ...stockoccupied,
    ...invoiceapply,
    ...storecashout,
    ...purchaseinvoice,
    ...purchaseinvoicestatement,
    ...contact,
    ...contract,
    ...customerService,
    ...department,
    ...module,
    ...product,
    ...role,
    ...pwd,
    ...setting,
    ...stock,
    ...warehouse,
    ...workflow,
    ...purchaseorder,
    ...stockinbound,
    ...stockinbounditem,
    ...purchaseinbound,
    ...purchaseinbounditem,
    ...salesorder,
    ...visit,
    ...errorinfo,
    ...user,
    ...allotoutbound,
    ...allotoutbounditem,
    ...allotoutbounditemproduct,
    ...feed,
    ...notification,
    ...todo,
    ...salesoutbound,
    ...salesoutbounditem,
    ...stockallotoutbounditem,
    ...stocklock,
    ...salesreturnbound,
    ...salesreturnbounditem,
    ...stocklog,
    ...stocklogchart,
    ...fixedcharge,
    ...fixedchargeitem,
    ...warehouselocation,
    ...warehouserelation,
    ...warehousecharge,
    ...stockoutbounditem,
    ...bankaccount,
    ...companyprepaid,
    ...fee,
    ...datacentersales,
    ...datacenterintobound,
    ...datacenteroutbound,
    ...inventoryorder,
    ...inventoryorderdetail,
    ...purchasepayment,
    ...store,
    ...purchasepaid,
    ...serviceorder,
    ...purchaserefund,
    ...stockrollback,
    ...financepayment,
    ...financepaymentitem,
    ...inventoryorderdetailextend,
    ...inventoryorderdetailmodify,
    ...appversion,
    ...storecontact,
    ...produceorder,
    ...produceordersource,
    ...produceordercomplete,
    ...productlabel,
    ...salesorderchecklog,
    ...productbox,
    ...modifylog,
    ...allotoutboundoutbound,
    ...allotoutboundoutbounditem,
    ...salesreport,
    ...allotoutboundinbound,
    ...allotoutboundinbounditem,
    ...otherinbound,
    ...storesales,
    ...purchasereturnbound,
    ...purchasereturnbounditem,
    ...offlinesalesorder,
    ...offlinesalesorderitem,
    ...productrelationidwarehouse,
    ...purchaseoutbound,
    ...purchaseoutbounditem,
    ...outbound,
    ...outbounditem,
    ...warehousestatement,
    ...statement,
    ...statementitem,
    ...statementcost,
    ...statementreceive,
    ...statementreceiveitem,
    ...salesrefund,
    ...salesrefunditem,
    ...operationlimit,
    ...salesoutboundabort,
    ...salesoutboundabortitem,
    ...warehousehead,
    ...stockbase,
    ...companyqrcode,
    ...purchaseordermodify,
    ...presalesreturnbound,
    ...presalesreturnbounditem,
    ...statementreceivemethod,
    ...statementchange,
    ...statementchangeitem,
    ...news,
    ...purchaseorderchangecost,
    ...brandarchive,
    ...brandarchiveresult,
    ...brandreward,
    ...brandrewardorder,
    ...brandrewardlog,
    ...stockstatuschange,
    ...feeloadsetting,
    ...feetransportsetting,
    ...feeloadcharge,
    ...feetransportcharge,
    ...stockchangeapply,
    ...bomproduceorder,
    ...bom,
    ...punishorder,
    ...punishorderdeal,
    ...productavgprice,
    ...companycheck,
    ...settlementReceiveSum,
    ...logisticsorder,
    ...logisticscarriager,
    ...logisticsfeed,
    ...logisticsreport,
    ...warehouserentorder,
    ...companynew,
    ...cargodamage,
    ...stockpurchaseteam,
    ...companycredit,
    ...companycredittemp,
    ...companystorecredit,
    ...companycreditlog,
    ...priceactivity,
    ...pricecheck,
    ...pricecheckitem,
    ...pricefixedruleoffline,
    ...pricefixedruleonlinecategory,
    ...pricefixedruleonlinenormal,
    ...pricefixedruleonlinespecial,
    ...priceguideoffline,
    ...priceguideonline,
    ...priceverifyruleoffline,
    ...customerratinglevel,
    ...gridhouse,
    ...gridhousedata,
    ...gridhousearap,
    ...gridhouseReport,
    ...financeresourcerelation,
    ...financeresource,
    ...cargoforce,
    ...datacentersalespt,
    ...statementproxyverification,
    ...statementproxy,
    ...salescost,
    ...warehousestatementrule,
    ...stockchecksecond,
    ...purchasestockup,
    ...purchasestockupitem,
    ...brandamount,
    ...stockcheckhistory,
    ...stockcreate,
    ...salesorderdeposit,
    ...stockstocktaking,
    ...stockstocktakingitem,
    ...purchaseinboundorder,
    ...purchaseinboundorderitem,
    ...purchaseinboundinvoice,
    ...purchaseclose,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],

})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role_name : null))
  }

  //路由变化时修改页面title
  if (to.meta.pageTitle) {
    document.title = to.meta.pageTitle
  }

  return next()
})

export default router
