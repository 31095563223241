export default [
  {
    path: '/apps/feeloadsetting/list',
    name: 'apps-feeloadsetting-list',
    component: () => import('@/views/apps/feeloadsetting/FeeLoadSettingList.vue'),
    meta: {
      pageTitle: '装卸费列表',
      breadcrumb: [
        {
          text: '装卸费',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/feeloadsetting/edit',
    name: 'apps-feeloadsetting-edit',
    component: () => import('@/views/apps/feeloadsetting/FeeLoadSettingEdit.vue'),
    meta: {
      pageTitle: '装卸费信息',
      breadcrumb: [
        {
          text: '装卸费',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/feeloadsetting/view',
    name: 'apps-feeloadsetting-view',
    component: () => import('@/views/apps/feeloadsetting/FeeLoadSettingEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
