export default [
  {
    path: '/apps/stockstocktaking/list',
    name: 'apps-stockstocktaking-list',
    component: () => import('@/views/apps/stockstocktaking/StockStocktakingList.vue'),
    meta: {
      pageTitle: '盘点库存调整列表',
      breadcrumb: [
        {
          text: '盘点库存调整',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/stockstocktaking/edit',
    name: 'apps-stockstocktaking-edit',
    component: () => import('@/views/apps/stockstocktaking/StockStocktakingEdit.vue'),
    meta: {
      pageTitle: '盘点库存调整信息',
      breadcrumb: [
        {
          text: '盘点库存调整',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/stockstocktaking/view',
    name: 'apps-stockstocktaking-view',
    component: () => import('@/views/apps/stockstocktaking/StockStocktakingEdit.vue'),
    meta: {
      pageTitle: '盘点库存调整',
      breadcrumb: [
        {
          text: '盘点库存调整',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
