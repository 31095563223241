export default [
  {
    path: '/apps/purchaseinbound/list',
    name: 'apps-purchaseinbound-list',
    component: () => import('@/views/apps/purchaseinbound/PurchaseInboundList.vue'),
    meta: {
      pageTitle: '入库通知单列表',
      breadcrumb: [
        {
          text: '入库通知单',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/otherInbound/edit',
    name: 'apps-otherInbound-edit',
    component: () => import('@/views/apps/otherInbound/otherInboundEdit.vue'),
    meta: {
      pageTitle: '其他入库单信息',
      breadcrumb: [
        {
          text: '其他入库单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchaseinbound/view',
    name: 'apps-purchaseinbound-view',
    component: () => import('@/views/apps/purchaseinbound/PurchaseInboundEdit.vue'),
    meta: {
      pageTitle: '入库通知单信息',
      breadcrumb: [
        {
          text: '入库通知单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchaseinbound/warehouseList',
    name: 'apps-purchaseinbound-warehouseList',
    component: () => import('@/views/apps/purchaseinbound/WarehouseList.vue'),
    meta: {
      pageTitle: '入库通知单列表',
      breadcrumb: [
        {
          text: '入库通知单',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
]
