export default [
  {
    path: '/apps/purchasestockupitem/list',
    name: 'apps-purchasestockupitem-list',
    component: () => import('@/views/apps/purchasestockupitem/PurchaseStockupItemList.vue'),
    meta: {
      pageTitle: '列表',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchasestockupitem/edit',
    name: 'apps-purchasestockupitem-edit',
    component: () => import('@/views/apps/purchasestockupitem/PurchaseStockupItemEdit.vue'),
    meta: {
      pageTitle: '信息',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchasestockupitem/view',
    name: 'apps-purchasestockupitem-view',
    component: () => import('@/views/apps/purchasestockupitem/PurchaseStockupItemEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
