import axios from '@axios'
import qs from 'qs'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        search(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/company/search', {params: params})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        financeSearch(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/company/financeSearch', {params: params})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        view(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/company/view', {params: params})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        edit(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/company/edit', {params: params})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        save(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/company/save', params)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        state(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/company/state', params)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        getChannelCustomer(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/company/searchChannelCustomer')
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        searchOffSalesChannelCustomer(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/company/searchOffSalesChannelCustomer', {params: params})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        searchSalesCustomer(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/company/searchSalesCustomer', {params: params})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        getGroup(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/company/searchGroup')
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        getSupplier(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/company/searchSupplier')
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        getWareHouse(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/company/searchWareHouse')
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        searchWareHouse(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/company/searchWareHouse', {params: params})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        searchChannelCustomer(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/company/searchChannelCustomer', {params: params})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        searchGroup(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/company/searchGroup', {params: params})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        searchSupplier(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/company/searchSupplier', {params: params})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        searchLoadTeam(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/company/searchLoadTeam', {params: params})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        searchLogistics(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/company/searchLogistics', {params: params})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        searchOffChannelAndChannel(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/company/searchOffChannelAndChannel', {params: params})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        exportExcel(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/company/exportExcel', params)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        changeStatus(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/company/changeStatus', params)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        pushBBMall(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/bbmall/company/v1/push', params)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        updateBBMall(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/bbmall/company/v1/update', params)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        pushCredit(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/bbmall/company/v1/push_credit', params)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        updateCredit(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/bbmall/company/v1/update_credit', params)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        audit(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/company/audit')
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        searchGroupAndOutside(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/company/searchGroupAndOutside', {params: params})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
