export default [
  {
    path: '/apps/product/list',
    name: 'apps-product-list',
    component: () => import('@/views/apps/product/ProductList.vue'),
    meta: {
      pageTitle: '商品列表',
      breadcrumb: [
        {
          text: '商品',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/product/edit',
    name: 'apps-product-edit',
    component: () => import('@/views/apps/product/ProductEdit.vue'),
    meta: {
      pageTitle: '商品信息',
      breadcrumb: [
        {
          text: '商品',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/product/view',
    name: 'apps-product-view',
    component: () => import('@/views/apps/product/ProductEdit.vue'),
    meta: {
      pageTitle: '商品',
      breadcrumb: [
        {
          text: '商品',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/product/finance/list',
    name: 'apps-product-finance-list',
    component: () => import('@/views/apps/product/product-finance/ProductList'),
    meta: {
      pageTitle: '待审核商品列表',
      breadcrumb: [
        {
          text: '待审核商品',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/productoperate/list',
    name: 'apps-product-operate-list',
    component: () => import('@/views/apps/product/product-operate/ProductList'),
    meta: {
      pageTitle: '待审核商品列表',
      breadcrumb: [
        {
          text: '待审核商品',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
]
