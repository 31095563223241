export default [
  {
    path: '/apps/companycheck/list',
    name: 'apps-company-check-list',
    component: () => import('@/views/apps/companycheck/CompanyCheckList.vue'),
    meta: {
      pageTitle: '客户名称变更列表',
      breadcrumb: [
        {
          text: '客户名称变更',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
]
