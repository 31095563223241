export default [
  {
    path: '/apps/brandrewardorder/list',
    name: 'apps-brandrewardorder-list',
    component: () => import('@/views/apps/brandrewardorder/BrandRewardOrderList.vue'),
    meta: {
      pageTitle: '调整额度列表',
      breadcrumb: [
        {
          text: '调整额度',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/brandrewardorder/edit',
    name: 'apps-brandrewardorder-edit',
    component: () => import('@/views/apps/brandrewardorder/BrandRewardOrderEdit.vue'),
    meta: {
      pageTitle: '调整额度信息',
      breadcrumb: [
        {
          text: '调整额度',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/brandrewardorder/view',
    name: 'apps-brandrewardorder-view',
    component: () => import('@/views/apps/brandrewardorder/BrandRewardOrderEdit.vue'),
    meta: {
      pageTitle: '调整额度信息',
      breadcrumb: [
        {
          text: '调整额度',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
