export const validatorPositive = value => {
  if (value > 0) {
    return true
  }
  return false
}

export const validatorNonnegative = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const twoDecimalsTive = value => {
  const regExp = /^[0-9]+(.[0-9]{1,2})?$/

  /* eslint-enable no-useless-escape */
  return regExp.test(value)
}

export const validatorPositiveInteger = value => {
  /* eslint-disable no-useless-escape */
  const regExp = /^[0-9]*[1-9][0-9]*$/
  /* eslint-enable no-useless-escape */
  return regExp.test(value)
}

export const validatorNonnegativeInteger = value => {
  /* eslint-disable no-useless-escape */
  const regExp = /^\d+$/
  /* eslint-enable no-useless-escape */
  return regExp.test(value)
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  return regExp.test(password)
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}
