export default [
  {
    path: '/apps/auto/execute/list',
    name: 'apps-auto-execute-list',
    component: () => import('@/views/apps/autoexecute/AutoExecute.vue'),
    meta: {
      pageTitle: '自动执行程序',
      breadcrumb: [
        {
          text: '自动执行程序',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
]
