export default [
  {
    path: '/apps/purchaseclose/list',
    name: 'apps-purchaseclose-list',
    component: () => import('@/views/apps/purchaseclose/PurchaseCloseList.vue'),
    meta: {
      pageTitle: '采购单关闭申请表列表',
      breadcrumb: [
        {
          text: '采购单关闭申请表',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchaseclose/edit',
    name: 'apps-purchaseclose-edit',
    component: () => import('@/views/apps/purchaseclose/PurchaseCloseEdit.vue'),
    meta: {
      pageTitle: '采购单关闭申请表信息',
      breadcrumb: [
        {
          text: '采购单关闭申请表',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchaseclose/view',
    name: 'apps-purchaseclose-view',
    component: () => import('@/views/apps/purchaseclose/PurchaseCloseEdit.vue'),
    meta: {
      pageTitle: '采购单关闭申请表',
      breadcrumb: [
        {
          text: '采购单关闭申请表',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
