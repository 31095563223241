export default [
  {
    path: '/apps/stockoutbounditem/list',
    name: 'apps-stockoutbounditem-list',
    component: () => import('@/views/apps/stockoutbounditem/StockOutboundItemList.vue'),
    meta: {
      pageTitle: '出库日志列表',
      breadcrumb: [
        {
          text: '出库日志',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/stockoutbounditem/edit',
    name: 'apps-stockoutbounditem-edit',
    component: () => import('@/views/apps/stockoutbounditem/StockOutboundItemEdit.vue'),
    meta: {
      pageTitle: '出库日志信息',
      breadcrumb: [
        {
          text: '出库日志',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/stockoutbounditem/view',
    name: 'apps-stockoutbounditem-view',
    component: () => import('@/views/apps/stockoutbounditem/StockOutboundItemEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
