import Vue from 'vue'
import {
    ToastPlugin, ModalPlugin, SidebarPlugin, TablePlugin, TooltipPlugin,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'
import axios from 'axios'

import '@validations'
import '@core/utils/flatpickr/flatpickr'

//dayjs
import '@core/utils/dayjs/dayjsConfig'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'
//import {ElementUIComponent} from "element-ui/types/component";
import 'element-ui/lib/theme-chalk/index.css';

Vue.prototype.$ajax = axios

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(SidebarPlugin)
Vue.use(TablePlugin)
Vue.use(TooltipPlugin)
//Vue.use(ElementUIComponent)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// import vue-flatpicker styles
require('@core/scss/vue/libs/vue-flatpicker.scss')

// import vue-select styles
require('@core/scss/vue/libs/vue-select.scss')

require('@core/css/style.css')

Vue.config.productionTip = false

/*
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
*/

if (!Vue.prototype.$code_table) {
    Vue.prototype.$code_table = {}
    store.dispatch('code/all').then(res => {
        Vue.prototype.$code_table = res.data.data
        new Vue({
            router,
            store,
            render: h => h(App),
        }).$mount('#app')

    })
}

//import * as socket from '@/libs/socket'
