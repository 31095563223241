export default [
  {
    path: '/apps/notification/list',
    name: 'apps-notification-list',
    component: () => import('@/views/apps/notification/NotificationList.vue'),
    meta: {
      pageTitle: '消息通知列表',
      breadcrumb: [
        {
          text: '消息通知',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/notification/edit',
    name: 'apps-notification-edit',
    component: () => import('@/views/apps/notification/NotificationEdit.vue'),
    meta: {
      pageTitle: '消息通知信息',
      breadcrumb: [
        {
          text: '消息通知',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/notification/view',
    name: 'apps-notification-view',
    component: () => import('@/views/apps/notification/NotificationEdit.vue'),
    meta: {
      pageTitle: '消息通知',
      breadcrumb: [
        {
          text: '消息通知',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
