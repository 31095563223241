export default [
    {
        path: '/apps/stockcheckhistory/list',
        name: 'apps-stockcheckhistory-list',
        component: () => import('@/views/apps/stockcheckhistory/StockcheckHistoryList.vue'),
        meta: {
            pageTitle: '库存修正列表',
            breadcrumb: [
                {
                    text: '库存修正',
                },
                {
                    text: '列表',
                    active: true,
                },
            ],
        },
    },
]
