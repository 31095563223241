export default [
  {
    path: '/apps/statementreceive/list',
    name: 'apps-statementreceive-list',
    component: () => import('@/views/apps/statementreceive/StatementReceiveList.vue'),
    meta: {
      pageTitle: '线下收款列表',
      breadcrumb: [
        {
          text: '线下收款',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/statementreceive/edit',
    name: 'apps-statementreceive-edit',
    component: () => import('@/views/apps/statementreceive/StatementReceiveEdit.vue'),
    meta: {
      pageTitle: '线下收款信息',
      breadcrumb: [
        {
          text: '线下收款',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },

  {
    path: '/apps/statementreceive/ol/list',
    name: 'apps-statementreceive-ol-list',
    component: () => import('@/views/apps/statementreceive/StatementReceiveList.vue'),
    meta: {
      pageTitle: '线上收款列表',
      breadcrumb: [
        {
          text: '线上收款',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/statementreceive/ol/edit',
    name: 'apps-statementreceive-ol-edit',
    component: () => import('@/views/apps/statementreceive/StatementReceiveEdit.vue'),
    meta: {
      pageTitle: '线上收款信息',
      breadcrumb: [
        {
          text: '线上收款',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },

  {
    path: '/apps/statementreceive/view',
    name: 'apps-statementreceive-view',
    component: () => import('@/views/apps/statementreceive/StatementReceiveEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
