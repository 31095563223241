export default [
  {
    path: '/apps/salesoutbounditem/list',
    name: 'apps-salesoutbounditem-list',
    component: () => import('@/views/apps/salesoutbounditem/SalesOutboundItemList.vue'),
    meta: {
      pageTitle: '列表',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/salesoutbounditem/edit',
    name: 'apps-salesoutbounditem-edit',
    component: () => import('@/views/apps/salesoutbounditem/SalesOutboundItemEdit.vue'),
    meta: {
      pageTitle: '信息',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/salesoutbounditem/salesoutbounditemwarehouselist',
    name: 'apps-salesoutbounditem-salesoutbounditemwarehouselist',
    component: () => import('@/views/apps/salesoutbounditem/SalesOutboundItemWarehouseList.vue'),
    meta: {
      pageTitle: '列表',
      breadcrumb: [
        {
          text: '出库通知单',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/salesoutbounditem/salesoutbounditemdetailedlist',
    name: 'apps-salesoutbounditem-salesoutbounditemdetailedlist',
    component: () => import('@/views/apps/salesoutbounditem/SalesOutboundItemDetailedList.vue'),
    meta: {
      pageTitle: '列表',
      breadcrumb: [
        {
          text: '出库通知单明细',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/salesoutbounditem/view',
    name: 'apps-salesoutbounditem-view',
    component: () => import('@/views/apps/salesoutbounditem/SalesOutboundItemEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
