export default [
  {
    path: '/apps/contact/list',
    name: 'apps-contact-list',
    component: () => import('@/views/apps/contact/ContactList.vue'),
    meta: {
      pageTitle: '联系人列表',
      breadcrumb: [
        {
          text: '联系人',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/contact/edit',
    name: 'apps-contact-edit',
    component: () => import('@/views/apps/contact/ContactEdit.vue'),
    meta: {
      pageTitle: '联系人信息',
      breadcrumb: [
        {
          text: '联系人',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/contact/view',
    name: 'apps-contact-view',
    component: () => import('@/views/apps/contact/ContactEdit.vue'),
    meta: {
      pageTitle: '联系人',
      breadcrumb: [
        {
          text: '联系人',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
