export default [
  {
    path: '/apps/datacenteroutbound/list',
    name: 'apps-datacenteroutbound-list',
    component: () => import('@/views/apps/datacenteroutbound/DatacenterOutboundList.vue'),
    meta: {
      pageTitle: '退供出库单据流水列表',
      breadcrumb: [
        {
          text: '退供出库单据流水',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/datacenteroutbound/edit',
    name: 'apps-datacenteroutbound-edit',
    component: () => import('@/views/apps/datacenteroutbound/DatacenterOutboundEdit.vue'),
    meta: {
      pageTitle: '退供出库单据流水信息',
      breadcrumb: [
        {
          text: '退供出库单据流水',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/datacenteroutbound/view',
    name: 'apps-datacenteroutbound-view',
    component: () => import('@/views/apps/datacenteroutbound/DatacenterOutboundEdit.vue'),
    meta: {
      pageTitle: '退供出库单据流水',
      breadcrumb: [
        {
          text: '退供出库单据流水',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
