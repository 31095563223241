export default [
  {
    path: '/apps/purchase/invoice/list',
    name: 'apps-purchase-invoice-list',
    component: () => import('@/views/apps/purchaseinvoice/PurchaseInvoiceList.vue'),
    meta: {
      pageTitle: '进项发票列表',
      breadcrumb: [
        {
          text: '进项发票',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchase/invoice/edit',
    name: 'apps-purchase-invoice-edit',
    component: () => import('@/views/apps/purchaseinvoice/PurchaseInvoiceEdit.vue'),
    meta: {
      pageTitle: '进项发票新增',
      breadcrumb: [
        {
          text: '进项发票',
        },
        {
          text: '新增',
          active: true,
        },
      ],
    },
  },
]
