export default [
  {
    path: '/apps/priceverifyruleoffline/list',
    name: 'apps-priceverifyruleoffline-list',
    component: () => import('@/views/apps/priceverifyruleoffline/PriceVerifyRuleOfflineList.vue'),
    meta: {
      pageTitle: '线下审核规则列表',
      breadcrumb: [
        {
          text: '线下审核规则',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/priceverifyruleoffline/edit',
    name: 'apps-priceverifyruleoffline-edit',
    component: () => import('@/views/apps/priceverifyruleoffline/PriceVerifyRuleOfflineEdit.vue'),
    meta: {
      pageTitle: '线下审核规则信息',
      breadcrumb: [
        {
          text: '线下审核规则',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/priceverifyruleoffline/view',
    name: 'apps-priceverifyruleoffline-view',
    component: () => import('@/views/apps/priceverifyruleoffline/PriceVerifyRuleOfflineEdit.vue'),
    meta: {
      pageTitle: '线下审核规则',
      breadcrumb: [
        {
          text: '线下审核规则',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
