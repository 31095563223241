import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {
    data: {}
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/code/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/code/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/code/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/code/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/code/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    all(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/code/all', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
