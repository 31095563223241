import Vue from 'vue'

// axios
import axios from 'axios'
import router from '@/router'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL + '/';
axios.defaults.headers = {"Access-Control-Allow-Origin": "*"};
axios.defaults.withCredentials = false;

const instance = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

// http request interceptors
instance.interceptors.request.use(
    config => {
        config.headers.Token =localStorage.getItem('token')
        config.headers.Path = location.pathname
        return config
    },
    err => {
        return Promise.reject(err)
    })

// http response interceptors
instance.interceptors.response.use(
    response => {
        //拦截响应，做统一处理
        if (response.data.code) {
            switch (response.data.code) {
                case 101:
                    localStorage.removeItem('token')
                    router.push({ name: 'auth-login', query: {
                            return_url: router.currentRoute.fullPath
                        } }).catch(() => {})
                    break;
                case 102:
                    router.push({ name: 'auth-forbidden'}).catch(() => {})
                    break;
                case 103:
                    router.push({ name: 'apps-pwd-update'}).catch(() => {})
                    break;
            }
        }
        return response
    },
    //接口错误状态处理，也就是说无响应时的处理
    error => {
        return Promise.reject(error.response.status) // 返回接口返回的错误信息
    })


Vue.prototype.$http = instance

export default instance
