export default [
  {
    path: '/apps/pricecheckitem/list',
    name: 'apps-pricecheckitem-list',
    component: () => import('@/views/apps/pricecheckitem/PriceCheckItemList.vue'),
    meta: {
      pageTitle: '售价审核单明细列表',
      breadcrumb: [
        {
          text: '售价审核单明细',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/pricecheckitem/edit',
    name: 'apps-pricecheckitem-edit',
    component: () => import('@/views/apps/pricecheckitem/PriceCheckItemEdit.vue'),
    meta: {
      pageTitle: '售价审核单明细信息',
      breadcrumb: [
        {
          text: '售价审核单明细',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/pricecheckitem/view',
    name: 'apps-pricecheckitem-view',
    component: () => import('@/views/apps/pricecheckitem/PriceCheckItemEdit.vue'),
    meta: {
      pageTitle: '售价审核单明细',
      breadcrumb: [
        {
          text: '售价审核单明细',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
