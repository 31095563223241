export default [
    {
        path: '/apps/stockinbound/list',
        name: 'apps-stockinbound-list',
        component: () => import('@/views/apps/stockinbound/StockInboundList.vue'),
        meta: {
            pageTitle: '入库日志列表',
            breadcrumb: [
                {
                    text: '入库日志',
                },
                {
                    text: '列表',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps/stockinbound/edit',
        name: 'apps-stockinbound-edit',
        component: () => import('@/views/apps/stockinbound/StockInboundEdit.vue'),
        meta: {
            pageTitle: '入库日志信息',
            breadcrumb: [
                {
                    text: '入库日志',
                },
                {
                    text: '信息',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps/stockinbound/view',
        name: 'apps-stockinbound-view',
        component: () => import('@/views/apps/stockinbound/StockInboundEdit.vue'),
        meta: {
            pageTitle: '入库日志信息',
            breadcrumb: [
                {
                    text: '入库日志',
                },
                {
                    text: '信息',
                    active: true,
                },
            ],
        },
    },
]
