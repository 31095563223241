export default [
  {
    path: '/apps/stockpurchaseteam/list',
    name: 'apps-stockpurchaseteam-list',
    component: () => import('@/views/apps/stockpurchaseteam/StockPurchaseTeamList.vue'),
    meta: {
      pageTitle: '库存销售权分配列表',
      breadcrumb: [
        {
          text: '库存销售权分配',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/stockpurchaseteam/edit',
    name: 'apps-stockpurchaseteam-edit',
    component: () => import('@/views/apps/stockpurchaseteam/StockPurchaseTeamEdit.vue'),
    meta: {
      pageTitle: '库存销售权分配信息',
      breadcrumb: [
        {
          text: '库存销售权分配',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/stockpurchaseteam/view',
    name: 'apps-stockpurchaseteam-view',
    component: () => import('@/views/apps/stockpurchaseteam/StockPurchaseTeamEdit.vue'),
    meta: {
      pageTitle: '库存销售权分配',
      breadcrumb: [
        {
          text: '库存销售权分配',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
