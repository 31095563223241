export default [
  {
    path: '/apps/stocklog/list',
    name: 'apps-stocklog-list',
    component: () => import('@/views/apps/stocklog/StockLogList.vue'),
    meta: {
      pageTitle: '仓库变更日志列表',
      breadcrumb: [
        {
          text: '仓库变更日志',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/stocklog/edit',
    name: 'apps-stocklog-edit',
    component: () => import('@/views/apps/stocklog/StockLogEdit.vue'),
    meta: {
      pageTitle: '仓库变更日志信息',
      breadcrumb: [
        {
          text: '仓库变更日志',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/stocklog/view',
    name: 'apps-stocklog-view',
    component: () => import('@/views/apps/stocklog/StockLogEdit.vue'),
    meta: {
      pageTitle: '仓库变更日志',
      breadcrumb: [
        {
          text: '仓库变更日志',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
