export default [
  {
    path: '/apps/salesoutbound/list',
    name: 'apps-salesoutbound-list',
    component: () => import('@/views/apps/salesoutbound/SalesOutboundList.vue'),
    meta: {
      pageTitle: '出库通知单列表',
      breadcrumb: [
        {
          text: '出库单列表',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/salesoutbound/edit',
    name: 'apps-salesoutbound-edit',
    component: () => import('@/views/apps/salesoutbound/SalesOutboundEdit.vue'),
    meta: {
      pageTitle: '出库通知单信息',
      breadcrumb: [
        {
          text: '出库单信息',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/salesoutbound/salesoutboundwarehouselist',
    name: 'apps-salesoutbound-salesoutboundwarehouselist',
    component: () => import('@/views/apps/salesoutbound/SalesOutboundWarehouseList.vue'),
    meta: {
      pageTitle: '出库通知单列表',
      breadcrumb: [
        {
          text: '出库通知单',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/salesoutbound/salesOutboundListDownload',
    name: 'apps-salesoutbound-salesOutboundListDownload',
    component: () => import('@/views/apps/salesoutbound/SalesOutboundListDownload.vue'),
    meta: {
      pageTitle: '出库通知单列表Download',
      breadcrumb: [
        {
          text: '出库通知单',
        },
        {
          text: '列表Download',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/salesoutbound/view',
    name: 'apps-salesoutbound-view',
    component: () => import('@/views/apps/salesoutbound/SalesOutboundEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
