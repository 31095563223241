export default [
  {
    path: '/apps/code/list',
    name: 'apps-code-list',
    component: () => import('@/views/apps/code/CodeList.vue'),
    meta: {
      pageTitle: '数据字典列表',
      breadcrumb: [
        {
          text: '数据字典',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/code/edit',
    name: 'apps-code-edit',
    component: () => import('@/views/apps/code/CodeEdit.vue'),
    meta: {
      pageTitle: '数据字典信息',
      breadcrumb: [
        {
          text: '数据字典',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/code/view',
    name: 'apps-code-view',
    component: () => import('@/views/apps/code/CodeEdit.vue'),
    meta: {
      pageTitle: '数据字典',
      breadcrumb: [
        {
          text: '数据字典',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
