export default [
  {
    path: '/apps/feed/list',
    name: 'apps-feed-list',
    component: () => import('@/views/apps/feed/FeedList.vue'),
    meta: {
      pageTitle: '消息流列表',
      breadcrumb: [
        {
          text: '消息流',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/feed/edit',
    name: 'apps-feed-edit',
    component: () => import('@/views/apps/feed/FeedEdit.vue'),
    meta: {
      pageTitle: '消息流信息',
      breadcrumb: [
        {
          text: '消息流',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/feed/view',
    name: 'apps-feed-view',
    component: () => import('@/views/apps/feed/FeedView.vue'),
    meta: {
      pageTitle: '消息流',
      breadcrumb: [
        {
          text: '消息流',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
