export default [
  {
    path: '/apps/statement/list',
    name: 'apps-statement-list',
    component: () => import('@/views/apps/statement/StatementList.vue'),
    meta: {
      pageTitle: '线下对账单列表',
      breadcrumb: [
        {
          text: '线下对账单',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/statement/edit',
    name: 'apps-statement-edit',
    component: () => import('@/views/apps/statement/StatementEdit.vue'),
    meta: {
      pageTitle: '线下对账单信息',
      breadcrumb: [
        {
          text: '线下对账单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },

  {
    path: '/apps/statement/ol/list',
    name: 'apps-statement-ol-list',
    component: () => import('@/views/apps/statement/StatementList.vue'),
    meta: {
      pageTitle: '线上对账单列表',
      breadcrumb: [
        {
          text: '线上对账单',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/statement/ol/edit',
    name: 'apps-statement-ol-edit',
    component: () => import('@/views/apps/statement/StatementEdit.vue'),
    meta: {
      pageTitle: '线上对账单信息',
      breadcrumb: [
        {
          text: '线上对账单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },

  {
    path: '/apps/statement/view',
    name: 'apps-statement-view',
    component: () => import('@/views/apps/statement/StatementEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
