export default [
  {
    path: '/apps/groupbrand/list',
    name: 'apps-groupbrand-list',
    component: () => import('@/views/apps/groupbrand/GroupBrandList.vue'),
    meta: {
      pageTitle: '集团品牌列表',
      breadcrumb: [
        {
          text: '集团品牌',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/groupbrand/edit',
    name: 'apps-groupbrand-edit',
    component: () => import('@/views/apps/groupbrand/GroupBrandEdit.vue'),
    meta: {
      pageTitle: '集团品牌信息',
      breadcrumb: [
        {
          text: '集团品牌',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/groupbrand/view',
    name: 'apps-groupbrand-view',
    component: () => import('@/views/apps/groupbrand/GroupBrandEdit.vue'),
    meta: {
      pageTitle: '集团品牌信息',
      breadcrumb: [
        {
          text: '集团品牌',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
