export default [
  {
    path: '/apps/gridhousereport/list',
    name: 'apps-gridhousereport-list',
    component: () => import('@/views/apps/gridhousereport/GridhouseReportList.vue'),
    meta: {
      pageTitle: '网格仓结算报表列表',
      breadcrumb: [
        {
          text: '结算报表',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
]
