export default [
  {
    path: '/apps/brandrewardlog/list',
    name: 'apps-brandrewardlog-list',
    component: () => import('@/views/apps/brandrewardlog/BrandRewardLogList.vue'),
    meta: {
      pageTitle: '资金池日志列表',
      breadcrumb: [
        {
          text: '资金池日志',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
]
