export default [
  {
    path: '/apps/operationlimit/list',
    name: 'apps-operationlimit-list',
    component: () => import('@/views/apps/operationlimit/OperationLimitList.vue'),
    meta: {
      pageTitle: '库存占用列表',
      breadcrumb: [
        {
          text: '库存占用',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/operationlimit/edit',
    name: 'apps-operationlimit-edit',
    component: () => import('@/views/apps/operationlimit/OperationLimitEdit.vue'),
    meta: {
      pageTitle: '库存占用信息',
      breadcrumb: [
        {
          text: '库存占用',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/operationlimit/view',
    name: 'apps-operationlimit-view',
    component: () => import('@/views/apps/operationlimit/OperationLimitEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
