export default [
  {
    path: '/apps/purchaseorder/list',
    name: 'apps-purchaseorder-list',
    component: () => import('@/views/apps/purchaseorder/PurchaseOrderList.vue'),
    meta: {
      pageTitle: '采购订单列表',
      breadcrumb: [
        {
          text: '采购订单',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchaseorder/edit',
    name: 'apps-purchaseorder-edit',
    component: () => import('@/views/apps/purchaseorder/PurchaseOrderEdit.vue'),
    meta: {
      pageTitle: '采购订单信息',
      breadcrumb: [
        {
          text: '采购订单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchaseorder/view',
    name: 'apps-purchaseorder-view',
    component: () => import('@/views/apps/purchaseorder/PurchaseOrderView.vue'),
    meta: {
      pageTitle: '采购订单变更',
      breadcrumb: [
        {
          text: '采购订单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
