export default [
    {
        path: '/apps/company/customer/new/list',
        name: 'apps-company-customer-new-list',
        component: () => import('@/views/apps/company-new/customer/CustomerList.vue'),
        meta: {
            pageTitle: '渠道商列表',
            breadcrumb: [
                {
                    text: '渠道商',
                },
                {
                    text: '列表',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps/company/customer/new/edit',
        name: 'apps-company-customer-new-edit',
        component: () => import('@/views/apps/company-new/customer/CustomerEdit.vue'),
        meta: {
            pageTitle: '渠道商信息',
            breadcrumb: [
                {
                    text: '渠道商',
                },
                {
                    text: '信息',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps/company/supplier/new/list',
        name: 'apps-company-supplier-new-list',
        component: () => import('@/views/apps/company-new/supplier/SupplierList.vue'),
        meta: {
            pageTitle: '供应商列表',
            breadcrumb: [
                {
                    text: '供应商',
                },
                {
                    text: '列表',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps/company/supplier/new/edit',
        name: 'apps-company-supplier-new-edit',
        component: () => import('@/views/apps/company-new/supplier/SupplierEdit.vue'),
        meta: {
            pageTitle: '供应商信息',
            breadcrumb: [
                {
                    text: '供应商',
                },
                {
                    text: '信息',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps/company/offlineCustomer/new/list',
        name: 'apps-company-offlineCustomer-new-list',
        component: () => import('@/views/apps/company-new/offlineCustomer/OfflineCustomerList.vue'),
        meta: {
            pageTitle: '终端客户列表',
            breadcrumb: [
                {
                    text: '终端客户',
                },
                {
                    text: '列表',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps/company/offlineCustomer/new/edit',
        name: 'apps-company-offlineCustomer-new-edit',
        component: () => import('@/views/apps/company-new/offlineCustomer/OfflineCustomerEdit.vue'),
        meta: {
            pageTitle: '终端客户信息',
            breadcrumb: [
                {
                    text: '终端客户',
                },
                {
                    text: '信息',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps/company/outsideCompany/new/list',
        name: 'apps-company-outsideCompany-new-list',
        component: () => import('@/views/apps/company-new/outsideCompany/OutsideCompanyList.vue'),
        meta: {
            pageTitle: '集团外公司列表',
            breadcrumb: [
                {
                    text: '集团外公司',
                },
                {
                    text: '列表',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps/company/outsideCompany/new/edit',
        name: 'apps-company-outsideCompany-new-edit',
        component: () => import('@/views/apps/company-new/outsideCompany/OutsideCompanyEdit.vue'),
        meta: {
            pageTitle: '集团外公司信息',
            breadcrumb: [
                {
                    text: '集团外公司',
                },
                {
                    text: '信息',
                    active: true,
                },
            ],
        },
    },
]
