export default [
  {
    path: '/apps/datacenterintobound/list',
    name: 'apps-datacenterintobound-list',
    component: () => import('@/views/apps/datacenterintobound/DatacenterIntoboundList.vue'),
    meta: {
      pageTitle: '采购入库单据流水列表',
      breadcrumb: [
        {
          text: '采购入库单据流水',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/datacenterintobound/edit',
    name: 'apps-datacenterintobound-edit',
    component: () => import('@/views/apps/datacenterintobound/DatacenterIntoboundEdit.vue'),
    meta: {
      pageTitle: '采购入库单据流水信息',
      breadcrumb: [
        {
          text: '采购入库单据流水',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/datacenterintobound/view',
    name: 'apps-datacenterintobound-view',
    component: () => import('@/views/apps/datacenterintobound/DatacenterIntoboundEdit.vue'),
    meta: {
      pageTitle: '采购入库单据流水',
      breadcrumb: [
        {
          text: '采购入库单据流水',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
