export default [
  {
    path: '/apps/warehousestatement/list',
    name: 'apps-warehousestatement-list',
    component: () => import('@/views/apps/warehousestatement/WarehouseStatementList.vue'),
    meta: {
      pageTitle: '账期设置列表',
      breadcrumb: [
        {
          text: '账期设置列表',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/warehousestatement/edit',
    name: 'apps-warehousestatement-edit',
    component: () => import('@/views/apps/warehousestatement/WarehouseStatementEdit.vue'),
    meta: {
      pageTitle: '账期设置信息',
      breadcrumb: [
        {
          text: '账期设置',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/warehousestatement/view',
    name: 'apps-warehousestatement-view',
    component: () => import('@/views/apps/warehousestatement/WarehouseStatementEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
