export default [
  {
    path: '/apps/brandamount/list',
    name: 'apps-brandamount-list',
    component: () => import('@/views/apps/brandamount/BrandAmountList.vue'),
    meta: {
      pageTitle: '品牌额度列表',
      breadcrumb: [
        {
          text: '品牌额度',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/brandamount/edit',
    name: 'apps-brandamount-edit',
    component: () => import('@/views/apps/brandamount/BrandAmountEdit.vue'),
    meta: {
      pageTitle: '信息',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/brandamount/view',
    name: 'apps-brandamount-view',
    component: () => import('@/views/apps/brandamount/BrandAmountEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
