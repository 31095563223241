export default [
  {
    path: '/apps/warehouserentorder/list',
    name: 'apps-warehouserentorder-list',
    component: () => import('@/views/apps/warehouserentorder/WarehouseRentOrderList.vue'),
    meta: {
      pageTitle: '仓库租金列表',
      breadcrumb: [
        {
          text: '仓库租金',
        },
        {
          text: '全部数据',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/warehouserentorderoneday/list',
    name: 'apps-warehouserentorder-oneday-list',
    component: () => import('@/views/apps/warehouserentorder/WarehouseRentOrderList.vue'),
    meta: {
      pageTitle: '仓库租金列表',
      breadcrumb: [
        {
          text: '仓库租金',
        },
        {
          text: '每日数据',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/warehouserentorder/edit',
    name: 'apps-warehouserentorder-edit',
    component: () => import('@/views/apps/warehouserentorder/WarehouseRentOrderEdit.vue'),
    meta: {
      pageTitle: '仓库租金信息',
      breadcrumb: [
        {
          text: '仓库租金',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/warehouserentorder/view',
    name: 'apps-warehouserentorder-view',
    component: () => import('@/views/apps/warehouserentorder/WarehouseRentOrderEdit.vue'),
    meta: {
      pageTitle: '仓库租金',
      breadcrumb: [
        {
          text: '仓库租金',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
