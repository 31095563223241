export default [
  {
    path: '/apps/allotoutbounditem/list',
    name: 'apps-allotoutbounditem-list',
    component: () => import('@/views/apps/allotoutbounditem/AllotOutboundItemList.vue'),
    meta: {
      pageTitle: '列表',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/allotoutbounditem/edit',
    name: 'apps-allotoutbounditem-edit',
    component: () => import('@/views/apps/allotoutbounditem/AllotOutboundItemEdit.vue'),
    meta: {
      pageTitle: '信息',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/allotoutbounditem/view',
    name: 'apps-allotoutbounditem-view',
    component: () => import('@/views/apps/allotoutbounditem/AllotOutboundItemEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/allotoutbounditem/warehouse',
    name: 'apps-allotoutbounditem-warehouse',
    component: () => import('@/views/apps/allotoutbounditem/WareHouse.vue'),
    meta: {
      pageTitle: '出库/入库回执单列表',
      breadcrumb: [
        {
          text: '出库/入库回执单列表',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
