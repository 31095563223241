export default [
  {
    path: '/apps/salesoutboundabort/list',
    name: 'apps-salesoutboundabort-list',
    component: () => import('@/views/apps/salesoutboundabort/SalesOutboundAbortList.vue'),
    meta: {
      pageTitle: '列表',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/salesoutboundabort/edit',
    name: 'apps-salesoutboundabort-edit',
    component: () => import('@/views/apps/salesoutboundabort/SalesOutboundAbortEdit.vue'),
    meta: {
      pageTitle: '信息',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/salesoutboundabort/view',
    name: 'apps-salesoutboundabort-view',
    component: () => import('@/views/apps/salesoutboundabort/SalesOutboundAbortEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
