import request from '@/store'
import moduleStore from '@/views/apps/module/moduleStore'

if (!request.hasModule('module')) request.registerModule('module', moduleStore)


// 动态生成左侧菜单列表
var supperManageModules = localStorage.getItem('tree') === null ? [] : eval('(' + localStorage.getItem('tree') + ')')

// 运营
export var returnmodules = [{icon: 'HomeIcon', id: 1, title: '我的看板'}, {
    children: [{
        icon: 'CheckSquareIcon',
        id: 14,
        route: {name: 'apps-todo', params: {state: 0}},
        title: '待办任务'
    }, {icon: 'CheckSquareIcon', id: 15, route: {name: 'apps-todo-send', params: {state: 1}}, title: '下发任务'}],
    icon: 'UsersIcon',
    id: 2,
    title: '任务列表'
}, {
    children: [{
        children: [{
            icon: 'null',
            id: 98,
            route: {name: 'apps-company-channel-list', query: {type: 1}},
            title: '列表'
        }, {icon: 'null', id: 99, route: {name: 'apps-company-channel-edit', query: {type: 1}}, title: '新增'}],
        icon: 'null',
        id: 93,
        title: '渠道客户'
    }, {
        children: [{
            icon: 'null',
            id: 100,
            route: {name: 'apps-company-branch-list', query: {type: 2}},
            title: '列表'
        }, {icon: 'null', id: 101, route: {name: 'apps-company-branch-list', query: {type: 2}}, title: '新增'}],
        icon: 'null',
        id: 94,
        title: '集团公司'
    }, {
        children: [{
            icon: 'null',
            id: 102,
            route: {name: 'apps-company-supplier-list', query: {type: 3}},
            title: '列表'
        }, {icon: 'null', id: 103, route: {name: 'apps-company-supplier-edit', query: {type: 3}}, title: '新增'}],
        icon: 'null',
        id: 95,
        title: '供应商'
    }, {
        children: [{
            icon: 'null',
            id: 104,
            route: {name: 'apps-company-warehouse-list', query: {type: 4}},
            title: '列表'
        }, {icon: 'null', id: 105, route: {name: 'apps-company-warehouse-edit', query: {type: 4}}, title: '新增'}],
        icon: 'null',
        id: 96,
        title: '仓库方'
    }, {
        children: [{
            icon: 'null',
            id: 106,
            route: {name: 'apps-company-logistics-list', query: {type: 5}},
            title: '列表'
        }, {icon: 'null', id: 107, route: {name: 'apps-company-logistics-edit', query: {type: 5}}, title: '新增'}],
        icon: 'null',
        id: 97,
        title: '物流公司'
    }], icon: 'GridIcon', id: 3, title: '企业信息'
}, {
    children: [{
        children: [{icon: 'null', id: 111, route: {name: 'apps-product-list'}, title: '列表'}, {
            icon: 'null',
            id: 112,
            route: {name: 'apps-product-edit'},
            title: '新增'
        }], icon: 'null', id: 108, title: '商品物料'
    }, {
        children: [{icon: 'null', id: 113, route: {name: 'apps-category-list'}, title: '列表'}, {
            icon: 'null',
            id: 114,
            route: {name: 'apps-category-edit'},
            title: '新增'
        }], icon: 'null', id: 109, title: '分类管理'
    }, {
        children: [{icon: 'null', id: 115, route: {name: 'apps-brand-list'}, title: '列表'}, {
            icon: 'null',
            id: 116,
            route: {name: 'apps-brand-edit'},
            title: '新增'
        }], icon: 'null', id: 110, title: '品牌管理'
    }], icon: 'AwardIcon', id: 4, title: '商品物料'
}, {
    children: [{
        children: [{icon: 'null', id: 120, route: {name: 'apps-allotoutbound-list'}, title: '列表'}],
        icon: 'null',
        id: 117,
        title: '库存调拨'
    }, {
        children: [{icon: 'null', id: 122, route: {name: 'apps-stocklock-list'}, title: '列表'}],
        icon: 'null',
        id: 118,
        title: '锁库列表'
    }, {
        children: [{icon: 'null', id: 123, route: {name: 'apps-store-list'}, title: '列表'}, {
            icon: 'null',
            id: 124,
            route: {name: 'apps-store-edit'},
            title: '新增'
        }], icon: 'null', id: 119, title: '商铺管理'
    }], icon: 'ShoppingBagIcon', id: 5, title: '运营管理'
}, {
    children: [{
        children: [{
            icon: 'null',
            id: 128,
            route: {name: 'apps-contract-purchase-list'},
            title: '列表'
        }, {icon: 'null', id: 129, route: {name: 'apps-contract-purchase-edit', query: {type: 1}}, title: '新增'}],
        icon: 'null',
        id: 125,
        title: '采购协议'
    }, {
        children: [{icon: 'null', id: 130, route: {name: 'apps-purchaseorder-list'}, title: '列表'}, {
            icon: 'null',
            id: 131,
            route: {name: 'apps-purchaseorder-edit'},
            title: '新增'
        }], icon: 'null', id: 126, title: '采购订单'
    }, {
        children: [{icon: 'null', id: 132, route: {name: 'apps-purchaseinbound-list'}, title: '列表'}],
        icon: 'null',
        id: 127,
        title: '入库通知单'
    }], icon: 'ShoppingCartIcon', id: 6, title: '采购管理'
}, {
    children: [{
        children: [{
            icon: 'null',
            id: 138,
            route: {name: 'apps-contract-sales-list', query: {type: 2}},
            title: '列表'
        }, {icon: 'null', id: 139, route: {name: 'apps-contract-sales-edit', query: {type: 2}}, title: '新增'}],
        icon: 'null',
        id: 133,
        title: '销售协议'
    }, {
        children: [{icon: 'null', id: 140, route: {name: 'apps-salesorder-list'}, title: '列表'}, {
            icon: 'null',
            id: 141,
            route: {name: 'apps-salesorder-edit'},
            title: '新增'
        }], icon: 'null', id: 134, title: '销售订单'
    }, {
        children: [{icon: 'null', id: 142, route: {name: 'apps-salesoutbound-list'}, title: '列表'}],
        icon: 'null',
        id: 135,
        title: '出库通知单'
    }, {
        children: [{icon: 'null', id: 143, route: {name: 'apps-salesreturnbound-list'}, title: '列表'}],
        icon: 'null',
        id: 136,
        title: '退货申请单'
    }, {
        children: [{icon: 'null', id: 144, route: {name: 'apps-customerservice-list'}, title: '列表'}],
        icon: 'null',
        id: 137,
        title: '售后订单'
    }], icon: 'DatabaseIcon', id: 7, title: '销售管理'
}, {
    children: [{
        children: [{icon: 'null', id: 151, route: {name: 'apps-stock-manage'}, title: '列表'}],
        icon: 'null',
        id: 145,
        title: '库存管理'
    }, {
        children: [{icon: 'null', id: 152, route: {name: 'apps-purchaseinbound-warehouseList'}, title: '列表'}],
        icon: 'null',
        id: 146,
        title: '入库通知单'
    }, {
        children: [{
            icon: 'null',
            id: 153,
            route: {name: 'apps-salesoutbound-salesoutboundwarehouselist'},
            title: '列表'
        }], icon: 'null', id: 147, title: '出库通知单'
    }, {
        children: [{icon: 'null', id: 154, route: {name: 'apps-allotoutbound-warehouse'}, title: '列表'}],
        icon: 'null',
        id: 148,
        title: '调拨通知单'
    }, {
        children: [{icon: 'null', id: 155, route: {name: 'apps-salesreturnbound-warehouseList'}, title: '列表'}],
        icon: 'null',
        id: 149,
        title: '退货通知单'
    }, {
        children: [{icon: 'null', id: 156, route: {name: 'apps-salesreturnbound-warehouseList'}, title: '列表'}],
        icon: 'null',
        id: 150,
        title: '库存盘点'
    }], icon: 'PercentIcon', id: 8, title: '仓库管理'
}, {
    children: [{
        children: [{icon: 'null', id: 158, route: {name: 'apps-datacentersales-list'}, title: '列表'}],
        icon: 'null',
        id: 157,
        title: '销售数据'
    }], icon: 'DollarSignIcon', id: 9, title: '结算中心'
}, {
    children: [{
        children: [{icon: 'null', id: 164, route: {name: 'apps-user-list'}, title: '列表'}, {
            icon: 'null',
            id: 165,
            route: {name: 'apps-user-edit'},
            title: '新增'
        }], icon: 'null', id: 159, title: '用户管理'
    }, {
        children: [{icon: 'null', id: 166, route: {name: 'apps-role-list'}, title: '列表'}, {
            icon: 'null',
            id: 167,
            route: {name: 'apps-role-edit'},
            title: '新增'
        }], icon: 'null', id: 160, title: '角色管理'
    }, {
        children: [{icon: 'null', id: 168, route: {name: 'apps-module-list'}, title: '列表'}, {
            icon: 'null',
            id: 169,
            route: {name: 'apps-module-edit'},
            title: '新增'
        }], icon: 'null', id: 161, title: '模块管理'
    }, {
        children: [{icon: 'null', id: 170, route: {name: 'apps-code-list'}, title: '列表'}, {
            icon: 'null',
            id: 171,
            route: {name: 'apps-code-edit'},
            title: '新增'
        }], icon: 'null', id: 162, title: '数据字典'
    }, {
        children: [{icon: 'null', id: 172, route: {name: 'apps-setting-list'}, title: '列表'}, {
            icon: 'null',
            id: 173,
            route: {name: 'apps-setting-edit'},
            title: '新增'
        }], icon: 'null', id: 163, title: '参数设置'
    }], icon: 'DollarSignIcon', id: 10, title: '系统管理'
}, {
    children: [{
        children: [{icon: 'null', id: 177, route: {name: 'apps-feed-list'}, title: '列表'}],
        icon: 'null',
        id: 174,
        title: '用户行为'
    }, {
        children: [{icon: 'null', id: 178, route: {name: 'apps-visit-list'}, title: '列表'}],
        icon: 'null',
        id: 175,
        title: '访问日志'
    }, {
        children: [{icon: 'null', id: 179, route: {name: 'apps-errorinfo-list'}, title: '列表'}],
        icon: 'null',
        id: 176,
        title: '错误日志'
    }], icon: 'DollarSignIcon', id: 11, title: '系统日志'
}, {icon: 'DollarSignIcon', id: 12, title: '财务中心'}, {icon: 'DollarSignIcon', id: 13, title: '统计分析'}]
