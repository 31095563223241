export default [
  {
    path: '/apps/pricefixedruleoffline/list',
    name: 'apps-pricefixedruleoffline-list',
    component: () => import('@/views/apps/pricefixedruleoffline/PriceFixedRuleOfflineList.vue'),
    meta: {
      pageTitle: '线上定价规则列表',
      breadcrumb: [
        {
          text: '线上定价规则',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/pricefixedrule/edit',
    name: 'apps-pricefixedrule-edit',
    component: () => import('@/views/apps/pricefixedruleoffline/PriceFixedRuleEdit.vue'),
    meta: {
      pageTitle: '销售定价规则信息',
      breadcrumb: [
        {
          text: '销售定价规则',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/pricefixedruleoffline/view',
    name: 'apps-pricefixedruleoffline-view',
    component: () => import('@/views/apps/pricefixedruleoffline/PriceFixedRuleOfflineEdit.vue'),
    meta: {
      pageTitle: '线上定价规则',
      breadcrumb: [
        {
          text: '线上定价规则',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
