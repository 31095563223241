export default [
  {
    path: '/apps/stocklock/list',
    name: 'apps-stocklock-list',
    component: () => import('@/views/apps/stocklock/StockLockList.vue'),
    meta: {
      pageTitle: '锁库列表',
      breadcrumb: [
        {
          text: '锁库列表',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/stocklock/edit',
    name: 'apps-stocklock-edit',
    component: () => import('@/views/apps/stocklock/StockLockEdit.vue'),
    meta: {
      pageTitle: '锁库信息',
      breadcrumb: [
        {
          text: '锁库信息',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/stocklock/view',
    name: 'apps-stocklock-view',
    component: () => import('@/views/apps/stocklock/StockLockEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
