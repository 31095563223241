export default [
  {
    path: '/apps/storesales/list',
    name: 'apps-storesales-list',
    component: () => import('@/views/apps/storesales/StoreSalesList.vue'),
    meta: {
      pageTitle: '店铺售卖员列表',
      breadcrumb: [
        {
          text: '店铺售卖员',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/storesales/edit',
    name: 'apps-storesales-edit',
    component: () => import('@/views/apps/storesales/StoreSalesEdit.vue'),
    meta: {
      pageTitle: '店铺售卖员信息',
      breadcrumb: [
        {
          text: '店铺售卖员',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/storesales/view',
    name: 'apps-storesales-view',
    component: () => import('@/views/apps/storesales/StoreSalesEdit.vue'),
    meta: {
      pageTitle: '店铺售卖员信息',
      breadcrumb: [
        {
          text: '店铺售卖员',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
