export default [
  {
    path: '/apps/salesreport/list',
    name: 'apps-salesreport-list',
    component: () => import('@/views/apps/salesreport/SalesReport.vue'),
    meta: {
      pageTitle: '库存及销售报表列表',
      breadcrumb: [
        {
          text: '库存及销售报表',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
]
