export default [
  {
    path: '/apps/offlinesalesorder/list',
    name: 'apps-offlinesalesorder-list',
    component: () => import('@/views/apps/offlinesalesorder/SalesOrderList.vue'),
    meta: {
      pageTitle: '线下销售订单列表',
      breadcrumb: [
        {
          text: '线下销售订单',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/offlinesalesorder/edit',
    name: 'apps-offlinesalesorder-edit',
    component: () => import('@/views/apps/offlinesalesorder/SalesOrderEdit.vue'),
    meta: {
      pageTitle: '线下销售订单信息',
      breadcrumb: [
        {
          text: '线下销售订单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/offlinesalesorder/view',
    name: 'apps-offlinesalesorder-view',
    component: () => import('@/views/apps/offlinesalesorder/SalesOrderEdit.vue'),
    meta: {
      pageTitle: '线下销售订单',
      breadcrumb: [
        {
          text: '线下销售订单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
