export default [
  {
    path: '/apps/allotoutboundinbound/list',
    name: 'apps-allotoutboundinbound-list',
    component: () => import('@/views/apps/allotoutboundinbound/AllotOutboundInboundList.vue'),
    meta: {
      pageTitle: '调拨入库单列表',
      breadcrumb: [
        {
          text: '调拨入库单',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/allotoutboundinbound/edit',
    name: 'apps-allotoutboundinbound-edit',
    component: () => import('@/views/apps/allotoutboundinbound/AllotOutboundInboundEdit.vue'),
    meta: {
      pageTitle: '调拨入库单信息',
      breadcrumb: [
        {
          text: '调拨入库单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/allotoutboundinbound/view',
    name: 'apps-allotoutboundinbound-view',
    component: () => import('@/views/apps/allotoutboundinbound/AllotOutboundInboundEdit.vue'),
    meta: {
      pageTitle: '调拨入库单信息',
      breadcrumb: [
        {
          text: '调拨入库单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
