export default [
  {
    path: '/apps/gridhousedata/list',
    name: 'apps-gridhousedata-list',
    component: () => import('@/views/apps/gridhousedata/GridhouseDataList.vue'),
    meta: {
      pageTitle: '网格仓数据维护',
      breadcrumb: [
        {
          text: '数据维护',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/gridhousedata/edit',
    name: 'apps-gridhousedata-edit',
    component: () => import('@/views/apps/gridhousedata/GridhouseDataEdit.vue'),
    meta: {
      pageTitle: '网格仓数据维护',
      breadcrumb: [
        {
          text: '数据维护',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
