export default [
  {
    path: '/apps/purchaseinbounditem/list',
    name: 'apps-purchaseinbounditem-list',
    component: () => import('@/views/apps/purchaseinbounditem/PurchaseInboundItemList.vue'),
    meta: {
      pageTitle: '入库通知单明细列表',
      breadcrumb: [
        {
          text: '入库通知单明细',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchaseinbounditem/edit',
    name: 'apps-purchaseinbounditem-edit',
    component: () => import('@/views/apps/purchaseinbounditem/PurchaseInboundItemEdit.vue'),
    meta: {
      pageTitle: '信息',
      breadcrumb: [
        {
          text: '入库通知单明细',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchaseinbounditem/view',
    name: 'apps-purchaseinbounditem-view',
    component: () => import('@/views/apps/purchaseinbounditem/PurchaseInboundItemEdit.vue'),
    meta: {
      pageTitle: '信息',
      breadcrumb: [
        {
          text: '入库通知单明细',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchaseinbounditem/warehouseList',
    name: 'apps-purchaseinbounditem-warehouseList',
    component: () => import('@/views/apps/purchaseinbounditem/WarehouseList.vue'),
    meta: {
      pageTitle: '入库通知单明细列表',
      breadcrumb: [
        {
          text: '入库通知单明细',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchaseinbounditem/inboundForm',
    name: 'apps-purchaseinbounditem-inboundForm',
    component: () => import('@/views/apps/purchaseinbounditem/inboundForm.vue'),
    meta: {
      pageTitle: '入库通知单信息',
      breadcrumb: [
        {
          text: '入库通知单明细',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
