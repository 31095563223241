export default [
  {
    path: '/apps/contract/purchase/list',
    name: 'apps-contract-purchase-list',
    component: () => import('@/views/apps/contract/ContractList.vue'),
    meta: {
      pageTitle: '采购框架协议列表',
      breadcrumb: [
        {
          text: '采购框架协议',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/contract/purchase/edit',
    name: 'apps-contract-purchase-edit',
    component: () => import('@/views/apps/contract/ContractEdit.vue'),
    meta: {
      pageTitle: '采购框架协议信息',
      breadcrumb: [
        {
          text: '采购框架协议',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/contract/sales/list',
    name: 'apps-contract-sales-list',
    component: () => import('@/views/apps/contract/ContractList.vue'),
    meta: {
      pageTitle: '销售框架协议列表',
      breadcrumb: [
        {
          text: '销售框架协议',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/contract/sales/edit',
    name: 'apps-contract-sales-edit',
    component: () => import('@/views/apps/contract/ContractEdit.vue'),
    meta: {
      pageTitle: '销售框架协议信息',
      breadcrumb: [
        {
          text: '销售框架协议',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/contract/view',
    name: 'apps-contract-purchase-view',
    component: () => import('@/views/apps/contract/ContractView.vue'),
    meta: {
      pageTitle: '采购框架协议变更',
      breadcrumb: [
        {
          text: '采购框架协议',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
