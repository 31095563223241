export default [
    {
        path: '/apps/logisticsreport/list',
        name: 'apps-logisticsreport-list',
        component: () => import('@/views/apps/logisticsreport/LogisticsReportList.vue'),
        meta: {
            pageTitle: '费用报表列表',
            breadcrumb: [
                {
                    text: '费用报表',
                },
                {
                    text: '列表',
                    active: true,
                },
            ],
        },
    },
]
