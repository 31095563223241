export default [
  {
    path: '/apps/stockrollback/list',
    name: 'apps-stockrollback-list',
    component: () => import('@/views/apps/stockrollback/StockRollbackList.vue'),
    meta: {
      pageTitle: '库存回滚审批单列表',
      breadcrumb: [
        {
          text: '库存回滚审批单',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/stockrollback/edit',
    name: 'apps-stockrollback-edit',
    component: () => import('@/views/apps/stockrollback/StockRollbackEdit.vue'),
    meta: {
      pageTitle: '库存回滚审批单信息',
      breadcrumb: [
        {
          text: '库存回滚审批单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/stockrollback/view',
    name: 'apps-stockrollback-view',
    component: () => import('@/views/apps/stockrollback/StockRollbackEdit.vue'),
    meta: {
      pageTitle: '库存回滚审批单信息',
      breadcrumb: [
        {
          text: '库存回滚审批单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
