export default [
  {
    path: '/apps/allotoutbound/list',
    name: 'apps-allotoutbound-list',
    component: () => import('@/views/apps/allotoutbound/AllotOutboundList.vue'),
    meta: {
      pageTitle: '调拨申请列表',
      breadcrumb: [
        {
          text: '调拨申请',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/allotoutbound/edit',
    name: 'apps-allotoutbound-edit',
    component: () => import('@/views/apps/allotoutbound/AllotOutboundEdit.vue'),
    meta: {
      pageTitle: '调拨申请信息',
      breadcrumb: [
        {
          text: '调拨通知单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/allotoutbound/edit',
    name: 'apps-allotoutbound-edit-new',
    component: () => import('@/views/apps/allotoutbound/AllotOutboundEdit.vue'),
    meta: {
      pageTitle: '调拨申请信息',
      breadcrumb: [
        {
          text: '调拨通知单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/allotoutbound/view',
    name: 'apps-allotoutbound-view',
    component: () => import('@/views/apps/allotoutbound/AllotOutboundEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/allotoutbound/warehouse',
    name: 'apps-allotoutbound-warehouse',
    component: () => import('@/views/apps/allotoutbound/WareHouse.vue'),
    meta: {
      pageTitle: '调拨通知单列表',
      breadcrumb: [
        {
          text: '调拨通知单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
