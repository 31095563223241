export default [
  {
    path: '/apps/pricecheck/list',
    name: 'apps-pricecheck-list',
    component: () => import('@/views/apps/pricecheck/PriceCheckList.vue'),
    meta: {
      pageTitle: '售价审核单列表',
      breadcrumb: [
        {
          text: '售价审核单',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/pricecheck/edit',
    name: 'apps-pricecheck-edit',
    component: () => import('@/views/apps/pricecheck/PriceCheckEdit.vue'),
    meta: {
      pageTitle: '售价审核单信息',
      breadcrumb: [
        {
          text: '售价审核单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/pricecheck/view',
    name: 'apps-pricecheck-view',
    component: () => import('@/views/apps/pricecheck/PriceCheckEdit.vue'),
    meta: {
      pageTitle: '售价审核单',
      breadcrumb: [
        {
          text: '售价审核单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
