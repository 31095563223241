export default [
  {
    path: '/apps/contract/purchase/one/list',
    name: 'apps-contract-purchase-one-list',
    component: () => import('@/views/apps/onecontractpurchase/OneContractPurchase.vue'),
    meta: {
      pageTitle: 'ONE采购合同',
      breadcrumb: [
        {
          text: 'ONE采购合同',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
]
