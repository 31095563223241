export default [
  {
    path: '/apps/produceordersource/list',
    name: 'apps-produceordersource-list',
    component: () => import('@/views/apps/produceordersource/ProduceOrderSourceList.vue'),
    meta: {
      pageTitle: '生产单原料列表',
      breadcrumb: [
        {
          text: '生产单原料',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/produceordersource/edit',
    name: 'apps-produceordersource-edit',
    component: () => import('@/views/apps/produceordersource/ProduceOrderSourceEdit.vue'),
    meta: {
      pageTitle: '生产单原料信息',
      breadcrumb: [
        {
          text: '生产单原料',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/produceordersource/view',
    name: 'apps-produceordersource-view',
    component: () => import('@/views/apps/produceordersource/ProduceOrderSourceEdit.vue'),
    meta: {
      pageTitle: '生产单原料',
      breadcrumb: [
        {
          text: '生产单原料',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
