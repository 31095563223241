export default [
  {
    path: '/apps/serviceorder/list',
    name: 'apps-serviceorder-list',
    component: () => import('@/views/apps/serviceorder/ServiceOrderList.vue'),
    meta: {
      pageTitle: '新售后订单列表',
      breadcrumb: [
        {
          text: '新售后订单',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/serviceorder/edit',
    name: 'apps-serviceorder-edit',
    component: () => import('@/views/apps/serviceorder/ServiceOrderEdit.vue'),
    meta: {
      pageTitle: '新售后订单信息',
      breadcrumb: [
        {
          text: '新售后订单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/serviceorder/view',
    name: 'apps-serviceorder-view',
    component: () => import('@/views/apps/serviceorder/ServiceOrderEdit.vue'),
    meta: {
      pageTitle: '新售后订单',
      breadcrumb: [
        {
          text: '新售后订单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
