export default [
  {
    path: '/apps/statementproxyverification/list',
    name: 'apps-statementproxyverification-list',
    component: () => import('@/views/apps/statementproxyverification/StatementProxyVerificationList.vue'),
    meta: {
      pageTitle: '代收核销列表',
      breadcrumb: [
        {
          text: '代收核销',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/statementproxyverification/edit',
    name: 'apps-statementproxyverification-edit',
    component: () => import('@/views/apps/statementproxyverification/StatementProxyVerificationEdit.vue'),
    meta: {
      pageTitle: '代收核销信息',
      breadcrumb: [
        {
          text: '代收核销',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
