export default [
  {
    path: '/apps/warehouselocation/list',
    name: 'apps-warehouselocation-list',
    component: () => import('@/views/apps/warehouselocation/WarehouseLocationList.vue'),
    meta: {
      pageTitle: '仓位列表',
      breadcrumb: [
        {
          text: '仓位',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/warehouselocation/edit',
    name: 'apps-warehouselocation-edit',
    component: () => import('@/views/apps/warehouselocation/WarehouseLocationEdit.vue'),
    meta: {
      pageTitle: '仓位信息',
      breadcrumb: [
        {
          text: '仓位',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/warehouselocation/view',
    name: 'apps-warehouselocation-view',
    component: () => import('@/views/apps/warehouselocation/WarehouseLocationEdit.vue'),
    meta: {
      pageTitle: '仓位',
      breadcrumb: [
        {
          text: '仓位',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
