export default [
  {
    path: '/apps/stockchecksecond/list',
    name: 'apps-stockchecksecond-list',
    component: () => import('@/views/apps/stockchecksecond/StockCheckSecondList.vue'),
    meta: {
      pageTitle: '并发库存差异',
      breadcrumb: [
        {
          text: '并发库存差异',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
]
