export default [
  {
    path: '/apps/companyqrcode/list',
    name: 'apps-companyqrcode-list',
    component: () => import('@/views/apps/companyqrcode/CompanyQrcodeList.vue'),
    meta: {
      pageTitle: '列表',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/companyqrcode/edit',
    name: 'apps-companyqrcode-edit',
    component: () => import('@/views/apps/companyqrcode/CompanyQrcodeEdit.vue'),
    meta: {
      pageTitle: '信息',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/companyqrcode/view',
    name: 'apps-companyqrcode-view',
    component: () => import('@/views/apps/companyqrcode/CompanyQrcodeEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
