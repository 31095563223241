export default [
  {
    path: '/apps/priceguideonline/list',
    name: 'apps-priceguideonline-list',
    component: () => import('@/views/apps/priceguideonline/PriceGuideOnlineList.vue'),
    meta: {
      pageTitle: '列表',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/priceguideonline/edit',
    name: 'apps-priceguideonline-edit',
    component: () => import('@/views/apps/priceguideonline/PriceGuideOnlineEdit.vue'),
    meta: {
      pageTitle: '信息',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/priceguideonline/view',
    name: 'apps-priceguideonline-view',
    component: () => import('@/views/apps/priceguideonline/PriceGuideOnlineEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
