export default [
  {
    path: '/apps/companyprepaid/list',
    name: 'apps-companyprepaid-list',
    component: () => import('@/views/apps/companyprepaid/CompanyPrepaidList.vue'),
    meta: {
      pageTitle: '预付款列表',
      breadcrumb: [
        {
          text: '预付款列表',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/companyprepaid/edit',
    name: 'apps-companyprepaid-edit',
    component: () => import('@/views/apps/companyprepaid/CompanyPrepaidEdit.vue'),
    meta: {
      pageTitle: '预付款信息',
      breadcrumb: [
        {
          text: '预付款信息',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/companyprepaid/view',
    name: 'apps-companyprepaid-view',
    component: () => import('@/views/apps/companyprepaid/CompanyPrepaidEdit.vue'),
    meta: {
      pageTitle: '预付款',
      breadcrumb: [
        {
          text: '预付款',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
