export default [
  {
    path: '/apps/salesorderchecklog/list',
    name: 'apps-salesorderchecklog-list',
    component: () => import('@/views/apps/salesorderchecklog/SalesOrderChecklogList.vue'),
    meta: {
      pageTitle: '销售订单审批日志列表',
      breadcrumb: [
        {
          text: '销售订单审批日志',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/salesorderchecklog/edit',
    name: 'apps-salesorderchecklog-edit',
    component: () => import('@/views/apps/salesorderchecklog/SalesOrderChecklogEdit.vue'),
    meta: {
      pageTitle: '销售订单审批日志信息',
      breadcrumb: [
        {
          text: '销售订单审批日志',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/salesorderchecklog/view',
    name: 'apps-salesorderchecklog-view',
    component: () => import('@/views/apps/salesorderchecklog/SalesOrderChecklogEdit.vue'),
    meta: {
      pageTitle: '销售订单审批日志',
      breadcrumb: [
        {
          text: '销售订单审批日志',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
