export default [
  {
    path: '/apps/bankaccount/list',
    name: 'apps-bankaccount-list',
    component: () => import('@/views/apps/bankaccount/BankAccountList.vue'),
    meta: {
      pageTitle: '银行账户列表',
      breadcrumb: [
        {
          text: '银行账户',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/bankaccount/edit',
    name: 'apps-bankaccount-edit',
    component: () => import('@/views/apps/bankaccount/BankAccountEdit.vue'),
    meta: {
      pageTitle: '银行账户信息',
      breadcrumb: [
        {
          text: '银行账户',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/bankaccount/view',
    name: 'apps-bankaccount-view',
    component: () => import('@/views/apps/bankaccount/BankAccountEdit.vue'),
    meta: {
      pageTitle: '银行账户',
      breadcrumb: [
        {
          text: '银行账户',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
