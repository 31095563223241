export default [
  {
    path: '/apps/pricefixedruleonlinespecial/list',
    name: 'apps-pricefixedruleonlinespecial-list',
    component: () => import('@/views/apps/pricefixedruleonlinespecial/PriceFixedRuleOnlineSpecialList.vue'),
    meta: {
      pageTitle: '线下定价规则 特殊品类列表',
      breadcrumb: [
        {
          text: '线下定价规则 特殊品类',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/pricefixedruleonlinespecial/edit',
    name: 'apps-pricefixedruleonlinespecial-edit',
    component: () => import('@/views/apps/pricefixedruleonlinespecial/PriceFixedRuleOnlineSpecialEdit.vue'),
    meta: {
      pageTitle: '线下定价规则 特殊品类信息',
      breadcrumb: [
        {
          text: '线下定价规则 特殊品类',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/pricefixedruleonlinespecial/view',
    name: 'apps-pricefixedruleonlinespecial-view',
    component: () => import('@/views/apps/pricefixedruleonlinespecial/PriceFixedRuleOnlineSpecialEdit.vue'),
    meta: {
      pageTitle: '线下定价规则 特殊品类',
      breadcrumb: [
        {
          text: '线下定价规则 特殊品类',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
