export default [
  {
    path: '/apps/category/list',
    name: 'apps-category-list',
    component: () => import('@/views/apps/category/CategoryList.vue'),
    meta: {
      pageTitle: '分类列表',
      breadcrumb: [
        {
          text: '分类',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/category/edit',
    name: 'apps-category-edit',
    component: () => import('@/views/apps/category/CategoryEdit.vue'),
    meta: {
      pageTitle: '分类信息',
      breadcrumb: [
        {
          text: '分类',
        },
        {
          text: '编辑',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/category/view',
    name: 'apps-category-view',
    component: () => import('@/views/apps/category/CategoryEdit.vue'),
    meta: {
      pageTitle: '分类',
      breadcrumb: [
        {
          text: '分类',
        },
        {
          text: '编辑',
          active: true,
        },
      ],
    },
  },
]
