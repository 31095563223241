export default [
  {
    path: '/apps/gridhouse/list',
    name: 'apps-gridhouse-list',
    component: () => import('@/views/apps/gridhouse/GridhouseList.vue'),
    meta: {
      pageTitle: '网格仓维护列表',
      breadcrumb: [
        {
          text: '网格仓维护',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/gridhouse/edit',
    name: 'apps-gridhouse-edit',
    component: () => import('@/views/apps/gridhouse/GridhouseEdit.vue'),
    meta: {
      pageTitle: '网格仓维护信息',
      breadcrumb: [
        {
          text: '网格仓维护',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
