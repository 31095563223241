export default [
  {
    path: '/apps/fixedcharge/list',
    name: 'apps-fixedcharge-list',
    component: () => import('@/views/apps/fixedcharge/FixedChargeList.vue'),
    meta: {
      pageTitle: '固定费用列表',
      breadcrumb: [
        {
          text: '固定费用',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/fixedcharge/edit',
    name: 'apps-fixedcharge-edit',
    component: () => import('@/views/apps/fixedcharge/FixedChargeEdit.vue'),
    meta: {
      pageTitle: '固定费用信息',
      breadcrumb: [
        {
          text: '固定费用',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/fixedcharge/view',
    name: 'apps-fixedcharge-view',
    component: () => import('@/views/apps/fixedcharge/FixedChargeEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
