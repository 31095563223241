export default [
  {
    path: '/apps/brandarchive/list',
    name: 'apps-brandarchive-list',
    component: () => import('@/views/apps/brandarchive/BrandArchiveList.vue'),
    meta: {
      pageTitle: '品牌档案列表',
      breadcrumb: [
        {
          text: '品牌档案',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/brandarchive/edit',
    name: 'apps-brandarchive-edit',
    component: () => import('@/views/apps/brandarchive/BrandArchiveEdit.vue'),
    meta: {
      pageTitle: '品牌档案信息',
      breadcrumb: [
        {
          text: '品牌档案',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/brandarchive/view',
    name: 'apps-brandarchive-view',
    component: () => import('@/views/apps/brandarchive/BrandArchiveEdit.vue'),
    meta: {
      pageTitle: '品牌档案信息',
      breadcrumb: [
        {
          text: '品牌档案',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
