export default [
  {
    path: '/apps/datacentersalespt/list',
    name: 'apps-datacentersalespt-list',
    component: () => import('@/views/apps/datacentersalespt/DatacenterSalesPtList.vue'),
    meta: {
      pageTitle: '场次销售数据仓库与销售权配置表列表',
      breadcrumb: [
        {
          text: '场次销售数据仓库与销售权配置表',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/datacentersalespt/edit',
    name: 'apps-datacentersalespt-edit',
    component: () => import('@/views/apps/datacentersalespt/DatacenterSalesPtEdit.vue'),
    meta: {
      pageTitle: '场次销售数据仓库与销售权配置表信息',
      breadcrumb: [
        {
          text: '场次销售数据仓库与销售权配置表',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/datacentersalespt/view',
    name: 'apps-datacentersalespt-view',
    component: () => import('@/views/apps/datacentersalespt/DatacenterSalesPtEdit.vue'),
    meta: {
      pageTitle: '场次销售数据仓库与销售权配置表',
      breadcrumb: [
        {
          text: '场次销售数据仓库与销售权配置表',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
