export default [
  {
    path: '/apps/statementcost/list',
    name: 'apps-statementcost-list',
    component: () => import('@/views/apps/statementcost/StatementCostList.vue'),
    meta: {
      pageTitle: '其他费用列表',
      breadcrumb: [
        {
          text: '其他费用',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/statementcost/edit',
    name: 'apps-statementcost-edit',
    component: () => import('@/views/apps/statementcost/StatementCostEdit.vue'),
    meta: {
      pageTitle: '其他费用信息',
      breadcrumb: [
        {
          text: '其他费用',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/statementcost/view',
    name: 'apps-statementcost-view',
    component: () => import('@/views/apps/statementcost/StatementCostEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
