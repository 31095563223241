export default [
  {
    path: '/apps/module/list',
    name: 'apps-module-list',
    component: () => import('@/views/apps/module/ModuleList.vue'),
    meta: {
      pageTitle: '模块列表',
      breadcrumb: [
        {
          text: '模块',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/module/edit',
    name: 'apps-module-edit',
    component: () => import('@/views/apps/module/ModuleEdit.vue'),
    meta: {
      pageTitle: '模块信息',
      breadcrumb: [
        {
          text: '模块',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/module/view',
    name: 'apps-module-view',
    component: () => import('@/views/apps/module/ModuleEdit.vue'),
    meta: {
      pageTitle: '模块',
      breadcrumb: [
        {
          text: '模块',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
