export default [
  {
    path: '/apps/presalesreturnbound/list',
    name: 'apps-presalesreturnbound-list',
    component: () => import('@/views/apps/presalesreturnbound/PreSalesReturnboundList.vue'),
    meta: {
      pageTitle: '销售退货单列表',
      breadcrumb: [
        {
          text: '销售退货单',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/presalesreturnbound/edit',
    name: 'apps-presalesreturnbound-edit',
    component: () => import('@/views/apps/presalesreturnbound/PreSalesReturnboundEdit.vue'),
    meta: {
      pageTitle: '销售退货单信息',
      breadcrumb: [
        {
          text: '销售退货单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/presalesreturnbound/view',
    name: 'apps-presalesreturnbound-view',
    component: () => import('@/views/apps/presalesreturnbound/PreSalesReturnboundEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/presalesreturnbound/return',
    name: 'apps-presalesreturnbound-return',
    component: () => import('@/views/apps/presalesreturnbound/PreSalesReturnboundReturn.vue'),
    meta: {
      pageTitle: '销售退货单信息',
      breadcrumb: [
        {
          text: '销售退货单',
        },
        {
          text: '退货信息',
          active: true,
        },
      ],
    },
  },
]
