export default [
  {
    path: '/apps/news/list',
    name: 'apps-news-list',
    component: () => import('@/views/apps/news/NewsList.vue'),
    meta: {
      pageTitle: '文章管理列表',
      breadcrumb: [
        {
          text: '文章管理',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },

  {
    path: '/apps/news/edit',
    name: 'apps-news-edit',
    component: () => import('@/views/apps/news/NewsEdit.vue'),
    meta: {
      pageTitle: '文章管理信息',
      breadcrumb: [
        {
          text: '文章管理',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/news/view',
    name: 'apps-news-view',
    component: () => import('@/views/apps/news/NewsEdit.vue'),
    meta: {
      pageTitle: '文章管理信息',
      breadcrumb: [
        {
          text: '文章管理',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
