export default [
  {
    path: '/apps/purchasepayment/list',
    name: 'apps-purchasepayment-list',
    component: () => import('@/views/apps/purchasepayment/PurchasePaymentList.vue'),
    meta: {
      pageTitle: '付款申请单列表',
      breadcrumb: [
        {
          text: '付款申请单',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchasepayment/edit',
    name: 'apps-purchasepayment-edit',
    component: () => import('@/views/apps/purchasepayment/PurchasePaymentEdit.vue'),
    meta: {
      pageTitle: '付款申请单信息',
      breadcrumb: [
        {
          text: '付款申请单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchasepayment/view',
    name: 'apps-purchasepayment-view',
    component: () => import('@/views/apps/purchasepayment/PurchasePaymentEdit.vue'),
    meta: {
      pageTitle: '付款申请单',
      breadcrumb: [
        {
          text: '付款申请单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
