export default [
  {
    path: '/apps/invoice/apply/list',
    name: 'apps-invoice-apply-list',
    component: () => import('@/views/apps/invoiceapply/InvoiceApplyList.vue'),
    meta: {
      pageTitle: '发票申请列表',
      breadcrumb: [
        {
          text: '发票申请',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/invoice/apply/edit',
    name: 'apps-invoice-apply-edit',
    component: () => import('@/views/apps/invoiceapply/InvoiceApplyEdit.vue'),
    meta: {
      pageTitle: '发票申请',
      breadcrumb: [
        {
          text: '发票申请',
        },
        {
          text: '新增',
          active: true,
        },
      ],
    },
  },
]
