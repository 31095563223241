import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  congratulations: {
    name: 'John',
    saleToday: '48900',
  },
  statisticsItems: [
    {
      icon: 'TrendingUpIcon',
      color: 'light-primary',
      title: '¥288.8万',
      subtitle: '销售额',
      customClass: 'mb-2 mb-xl-0',
    },
    {
      icon: 'UserIcon',
      color: 'light-info',
      title: '108家',
      subtitle: '店铺数',
      customClass: 'mb-2 mb-xl-0',
    },
    {
      icon: 'BoxIcon',
      color: 'light-danger',
      title: '1.2万件',
      subtitle: '交易量',
      customClass: 'mb-2 mb-sm-0',
    },
    {
      icon: 'DollarSignIcon',
      color: 'light-success',
      title: '¥26.8万',
      subtitle: '毛利',
      customClass: '',
    },
  ],
  statisticsOrder: {
    series: [
      {
        name: '2020',
        data: [45, 85, 65, 45, 65],
      },
    ],
  },
  statisticsProfit: {
    series: [
      {
        data: [0, 20, 5, 30, 15, 45],
      },
    ],
  },
  earningsChart: {
    series: [53, 16, 31],
  },
  revenue: {
    years: ['2020', '2019', '2018'],
    price: '25,852',
    budget: '56,800',
    revenueReport: {
      series: [
        {
          name: 'Earning',
          data: [95, 177, 284, 256, 105, 63, 168, 218, 72],
        },
        {
          name: 'Expense',
          data: [-145, -80, -60, -180, -100, -60, -85, -75, -100],
        },
      ],
    },
    budgetChart: {
      series: [
        {
          data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
        },
        {
          data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
        },
      ],
    },
  },
  companyTable: [
    {
      avatarImg: require('@/assets/images/icons/ddmc.jpeg'),
      title: '徐州优选百货店',
      subtitle: '江苏-徐州@多多买菜',
      avatarIcon: 'MonitorIcon',
      avatarColor: 'light-primary',
      avatarTitle: 'Technology',
      viewTitle: '7,820,376',
      viewsub: 'in 24 hours',
      products: '90,888件',
      revenue: '563,891.2',
      sales: '68',
      loss: true,
    },
    {
      avatarImg: require('@/assets/images/icons/ddmc.jpeg'),
      title: '苏州云茂好货店',
      subtitle: '江苏-苏州@多多买菜',
      avatarIcon: 'CoffeeIcon',
      avatarColor: 'light-success',
      avatarTitle: 'Grocery',
      viewTitle: '6,730,289',
      viewsub: 'in 2 days',
      products: '78,888件',
      revenue: '439,768.51',
      sales: '97',
      loss: false,
    },
    {
      avatarImg: require('@/assets/images/icons/ddmc.jpeg'),
      title: '无锡云贸好货店',
      subtitle: '江苏-无锡@多多买菜',
      avatarIcon: 'WatchIcon',
      avatarColor: 'light-warning',
      avatarTitle: 'Fashion',
      viewTitle: '4,397,990',
      viewsub: 'in 5 days',
      products: '55,666件',
      revenue: '434,322.29',
      sales: '62',
      loss: false,
    },
    {
      avatarImg: require('@/assets/images/icons/ddmc.jpeg'),
      title: '淮安云贸好货店',
      subtitle: '江苏-淮安@多多买菜',
      avatarIcon: 'MonitorIcon',
      avatarColor: 'light-primary',
      avatarTitle: 'Technology',
      viewTitle: '3,900,853',
      viewsub: 'in 24 hours',
      products: '46,388件',
      revenue: '279,291.01',
      sales: '88',
      loss: false,
    },
    {
      avatarImg: require('@/assets/images/icons/ddmc.jpeg'),
      title: '南京云贸好货店',
      subtitle: '江苏-南京@多多买菜',
      avatarIcon: 'CoffeeIcon',
      avatarColor: 'light-success',
      avatarTitle: 'Grocery',
      viewTitle: '3,435,805',
      viewsub: 'in 1 week',
      products: '35,888件',
      revenue: '190,783.93',
      sales: '16',
      loss: true,
    },
    {
      avatarImg: require('@/assets/images/icons/ddmc.jpeg'),
      title: '金华云贸好货店',
      subtitle: '浙江-金华@多多买菜',
      avatarIcon: 'WatchIcon',
      avatarColor: 'light-warning',
      avatarTitle: 'Fashion',
      viewTitle: '987,453',
      viewsub: 'in 1 month',
      products: '28,688件',
      revenue: '103,780.05',
      sales: '78',
      loss: false,
    },
    {
      avatarImg: require('@/assets/images/icons/ddmc.jpeg'),
      title: '济南云贸好货店',
      subtitle: '山东-济南@多多买菜',
      avatarIcon: 'WatchIcon',
      avatarColor: 'light-warning',
      avatarTitle: 'Fashion',
      viewTitle: '840,579',
      viewsub: 'in 12 hours',
      products: '16,388件',
      revenue: '73,531.49',
      sales: '42',
      loss: false,
    },
  ],
  meetup: {
    mediaData: [
      { avatar: 'CalendarIcon', title: 'Sat, May 25, 2020', subtitle: '10:AM to 6:PM' },
      { avatar: 'MapPinIcon', title: 'Central Park', subtitle: 'Manhattan, New york City' },
    ],
    avatars: [
      { avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'), fullName: 'Billy Hopkins' },
      { avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'), fullName: 'Amy Carson' },
      { avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'), fullName: 'Brandon Miles' },
      { avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'), fullName: 'Daisy Weber' },
      { avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'), fullName: 'Jenny Looper' },
    ],
  },
  goalOverview: {
    completed: '786,617',
    inProgress: '13,561',
    series: [83],
  },
  transactionData: [
    {
      mode: '销售收入',
      types: '收入',
      avatar: 'PocketIcon',
      avatarVariant: 'light-primary',
      payment: '¥68,000,000',
      deduction: false,
    },
    {
      mode: '采购支出',
      types: '支出',
      avatar: 'CheckIcon',
      avatarVariant: 'light-success',
      payment: '-¥59,000,000',
      deduction: true,
    },
    {
      mode: '各类附加费',
      types: '支出',
      avatar: 'DollarSignIcon',
      avatarVariant: 'light-danger',
      payment: '-¥3,500,000',
      deduction: true,
    },
    {
      mode: '渠道服务费',
      types: '支出',
      avatar: 'CreditCardIcon',
      avatarVariant: 'light-warning',
      payment: '-¥408,000',
      deduction: true,
    },
    {
      mode: '货物损耗',
      types: '支出',
      avatar: 'TrendingUpIcon',
      avatarVariant: 'light-info',
      payment: '-¥1,288,000',
      deduction: true,
    },
  ],
}
/* eslint-disable global-require */
mock.onGet('/ecommerce/data').reply(() => [200, data])
