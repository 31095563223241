import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import codeStore from "@/views/apps/code/codeStore";
import attachmentStore from "@/views/apps/attachment/attachmentStore";
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import companyStore from "@/views/apps/company/companyStore";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'code': codeStore,
    'attachment': attachmentStore,
    'company': companyStore,
  },
  getters: {
  },
  strict: process.env.DEV,
})


