export default [
    // {
    //     path: '/apps/note',
    //     name: 'apps-note',
    //     component: () => import('@/views/apps/note/Note.vue'),
    //     meta: {
    //         pageTitle: '列表',
    //         breadcrumb: [
    //             {
    //                 text: '记事管理',
    //             },
    //             {
    //                 text: '列表',
    //                 active: true,
    //             },
    //         ],
    //     },
    // },
    {
        path: '/apps/note',
        name: 'apps-note',
        component: () => import('@/views/apps/note/Note.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
        },
    },
    {
        path: '/apps/note/:folder',
        name: 'apps-note-folder',
        component: () => import('@/views/apps/note/Note.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
            navActiveLink: 'apps-note',
        },
        beforeEnter(to, _, next) {
            if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
            else next({ name: 'error-404' })
        },
    },
    {
        path: '/apps/note/label/:label',
        name: 'apps-note-label',
        component: () => import('@/views/apps/note/Note.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
            navActiveLink: 'apps-note',
        },
        beforeEnter(to, _, next) {
            if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
            else next({ name: 'error-404' })
        },
    },
]
