export default [
  {
    path: '/apps/companycreditlog/list',
    name: 'apps-companycreditlog-list',
    component: () => import('@/views/apps/companycreditlog/CompanyCreditLogList.vue'),
    meta: {
      pageTitle: '额度调整日志列表',
      breadcrumb: [
        {
          text: '额度调整日志',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },

]
