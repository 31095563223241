export default [
  {
    path: '/apps/productrelationidwarehouse/list',
    name: 'apps-productrelationidwarehouse-list',
    component: () => import('@/views/apps/productrelationidwarehouse/ProductRelationidWarehouseList.vue'),
    meta: {
      pageTitle: '商品物料映射列表',
      breadcrumb: [
        {
          text: '物料映射',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/productrelationidwarehouse/edit',
    name: 'apps-productrelationidwarehouse-edit',
    component: () => import('@/views/apps/productrelationidwarehouse/ProductRelationidWarehouseEdit.vue'),
    meta: {
      pageTitle: '商品物料映射信息',
      breadcrumb: [
        {
          text: '商品物料映射',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/productrelationidwarehouse/view',
    name: 'apps-productrelationidwarehouse-view',
    component: () => import('@/views/apps/productrelationidwarehouse/ProductRelationidWarehouseEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
