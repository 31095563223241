export default [
  {
    path: '/apps/purchaseordermodify/list',
    name: 'apps-purchaseordermodify-list',
    component: () => import('@/views/apps/purchaseordermodify/PurchaseOrderModifyList.vue'),
    meta: {
      pageTitle: '成本调整单列表',
      breadcrumb: [
        {
          text: '成本调整单',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchaseordermodify/edit',
    name: 'apps-purchaseordermodify-edit',
    component: () => import('@/views/apps/purchaseordermodify/PurchaseOrderModifyEdit.vue'),
    meta: {
      pageTitle: '成本调整单信息',
      breadcrumb: [
        {
          text: '成本调整单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchaseordermodify/view',
    name: 'apps-purchaseordermodify-view',
    component: () => import('@/views/apps/purchaseordermodify/PurchaseOrderModifyEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
