import flatpickr from 'flatpickr'
import { Mandarin as zh } from 'flatpickr/dist/l10n/zh.js'

/*本地化参数见
* https://github.com/flatpickr/flatpickr/blob/master/src/types/locale.ts
* */

// zh.firstDayOfWeek = 1
// zh.time_24hr = true
zh.weekdays.shorthand = ['日', '一', '二', '三', '四', '五', '六']
/*zh.months.shorthand = [
  '1月',
  '2月',
  '3月',
  '4月',
  '5月',
  '6月',
  '7月',
  '8月',
  '9月',
  '10月',
  '11月',
  '12月',
]*/
zh.months.longhand = [
  '1月',
  '2月',
  '3月',
  '4月',
  '5月',
  '6月',
  '7月',
  '8月',
  '9月',
  '10月',
  '11月',
  '12月',
]

zh.amPM = ['上午', '下午']
zh.weekAbbreviation = "周"
zh.yearAriaLabel = "年"
zh.monthAriaLabel = "月"
zh.hourAriaLabel = "时"
zh.minuteAriaLabel = "分"
zh.toggleTitle = "切换上下午"
flatpickr.localize(zh)
