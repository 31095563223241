export default [
  {
    path: '/apps/inventoryorderdetailextend/list',
    name: 'apps-inventoryorderdetailextend-list',
    component: () => import('@/views/apps/inventoryorderdetailextend/InventoryOrderDetailExtendList.vue'),
    meta: {
      pageTitle: '盘点单明细_出入库记录列表',
      breadcrumb: [
        {
          text: '盘点单明细_出入库记录',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inventoryorderdetailextend/edit',
    name: 'apps-inventoryorderdetailextend-edit',
    component: () => import('@/views/apps/inventoryorderdetailextend/InventoryOrderDetailExtendEdit.vue'),
    meta: {
      pageTitle: '盘点单明细_出入库记录信息',
      breadcrumb: [
        {
          text: '盘点单明细_出入库记录',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inventoryorderdetailextend/view',
    name: 'apps-inventoryorderdetailextend-view',
    component: () => import('@/views/apps/inventoryorderdetailextend/InventoryOrderDetailExtendEdit.vue'),
    meta: {
      pageTitle: '盘点单明细_出入库记录',
      breadcrumb: [
        {
          text: '盘点单明细_出入库记录',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
