export default [
  {
    path: '/apps/errorinfo/list',
    name: 'apps-errorinfo-list',
    component: () => import('@/views/apps/errorinfo/ErrorInfoList.vue'),
    meta: {
      pageTitle: '系统错误日志列表',
      breadcrumb: [
        {
          text: '系统错误日志',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/errorinfo/edit',
    name: 'apps-errorinfo-edit',
    component: () => import('@/views/apps/errorinfo/ErrorInfoEdit.vue'),
    meta: {
      pageTitle: '系统错误日志信息',
      breadcrumb: [
        {
          text: '系统错误日志',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/errorinfo/view',
    name: 'apps-errorinfo-view',
    component: () => import('@/views/apps/errorinfo/ErrorInfoSelect.vue'),
    meta: {
      pageTitle: '系统错误日志',
      breadcrumb: [
        {
          text: '系统错误日志',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
