export default [
  {
    path: '/apps/fee/list',
    name: 'apps-fee-list',
    component: () => import('@/views/apps/fee/FeeList.vue'),
    meta: {
      pageTitle: '附加费列表',
      breadcrumb: [
        {
          text: '附加费',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/fee/edit',
    name: 'apps-fee-edit',
    component: () => import('@/views/apps/fee/FeeEdit.vue'),
    meta: {
      pageTitle: '附加费信息',
      breadcrumb: [
        {
          text: '附加费',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/fee/view',
    name: 'apps-fee-view',
    component: () => import('@/views/apps/fee/FeeEdit.vue'),
    meta: {
      pageTitle: '附加费',
      breadcrumb: [
        {
          text: '附加费',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
