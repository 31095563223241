export default [
  {
    path: '/apps/salesreturnbound/list',
    name: 'apps-salesreturnbound-list',
    component: () => import('@/views/apps/salesreturnbound/SalesReturnboundList.vue'),
    meta: {
      pageTitle: '销售退货单列表',
      breadcrumb: [
        {
          text: '销售退货单',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/salesreturnbound/edit',
    name: 'apps-salesreturnbound-edit',
    component: () => import('@/views/apps/salesreturnbound/SalesReturnboundEdit.vue'),
    meta: {
      pageTitle: '销售退货单信息',
      breadcrumb: [
        {
          text: '销售退货单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/salesreturnbound/view',
    name: 'apps-salesreturnbound-view',
    component: () => import('@/views/apps/salesreturnbound/SalesReturnboundEdit.vue'),
    meta: {
      pageTitle: '销售退货单',
      breadcrumb: [
        {
          text: '销售退货单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/salesreturnbound/warehouseList',
    name: 'apps-salesreturnbound-warehouseList',
    component: () => import('@/views/apps/salesreturnbound/WarehouseList.vue'),
    meta: {
      pageTitle: '退货入库单列表',
      breadcrumb: [
        {
          text: '退货入库单',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
]
