export default [
  {
    path: '/apps/logisticsorder/list',
    name: 'apps-logisticsorder-list',
    component: () => import('@/views/apps/logisticsorder/LogisticsOrderList.vue'),
    meta: {
      pageTitle: '物流订单列表',
      breadcrumb: [
        {
          text: '物流订单',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/logisticsorder/edit',
    name: 'apps-logisticsorder-edit',
    component: () => import('@/views/apps/logisticsorder/LogisticsOrderEdit.vue'),
    meta: {
      pageTitle: '物流订单信息',
      breadcrumb: [
        {
          text: '物流订单信息',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },

]
