import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  // knowledge base
  knowledgeBase: [
    {
      id: 1,
      category: 'company',
      img: require('@/assets/images/banner/upgrade.png'),
      title: '企业信息',
      desc: '企业信息：包括渠道客户,集团公司,供应商,承运商,仓库方,装卸队等',
    },
    {
      id: 2,
      category: 'purchase',
      img: require('@/assets/images/banner/banner-34.jpg'),
      title: '采购模块',
      desc: '采购相关:包括采购订单,物料信息等',
    },
    // {
    //   id: 6,
    //   category: 'demand-generation',
    //   img: require('@/assets/images/illustration/demand.svg'),
    //   title: 'Demand Generation',
    //   desc: 'Competent means we will never take anything for granted.',
    // },
  ],
  categoryData: [
    {
      id: 0,
      title: '渠道客户',
      icon: 'SettingsIcon',
      iconColor: 'text-primary',
      category: 'company',
      questions: [
        {
          id: 0,
          question: '导入渠道客户',
          slug: 'how-secure-is-my-password',
          href: '',
          type: 'import',
          methodName:'importCompany',
        },
      ],
    },
    {
      id: 1,
      title: '采购相关导入',
      icon: 'LinkIcon',
      iconColor: 'text-success',
      category: 'purchase',
      questions: [
        {
          id: 0,
          question: '采购订单商品导入模板',
          slug: 'how-secure-is-my-password',
          href:  'https://oss.sbc.xyb2b.com/sbc/purchase_inbound/d8e12c4e89245ae21ca5cebebe315416.xlsx',
          type:'download',
          methodName:'',
        },
        {
          id: 1,
          question: '采购管理商品物料批量导入模板',
          slug: 'how-secure-is-my-password',
          href: 'https://oss.sbc.xyb2b.com/sbc/bom_202210/8e326157e8b2ff33acae968ef7c3a676.xlsx',
          type:'download',
          methodName:'',
        },
      ],
    },

    {
      id: 2,
      title: '采购相关内容',
      icon: 'LinkIcon',
      iconColor: 'text-success',
      category: 'purchase',
      questions: [
        {
          id: 0,
          question: '暂无内容',
          slug: 'how-secure-is-my-password',
          href:  '',
          type:'download',
          methodName:'',
        },

      ],
    },

  ],
}
/* eslint-disable global-require */
mock.onGet('/administrator/export/knowledge_base').reply(() => [200, data.knowledgeBase])
mock.onGet('/administrator/export/category').reply(() => [200, data.categoryData])

