export default [
  {
    path: '/apps/salesreturnbounditem/list',
    name: 'apps-salesreturnbounditem-list',
    component: () => import('@/views/apps/salesreturnbounditem/SalesReturnboundItemList.vue'),
    meta: {
      pageTitle: '销售退货单明细列表',
      breadcrumb: [
        {
          text: '销售退货单明细',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/salesreturnbounditem/edit',
    name: 'apps-salesreturnbounditem-edit',
    component: () => import('@/views/apps/salesreturnbounditem/SalesReturnboundItemEdit.vue'),
    meta: {
      pageTitle: '销售退货单明细信息',
      breadcrumb: [
        {
          text: '销售退货单明细',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/salesreturnbounditem/view',
    name: 'apps-salesreturnbounditem-view',
    component: () => import('@/views/apps/salesreturnbounditem/SalesReturnboundItemEdit.vue'),
    meta: {
      pageTitle: '销售退货单明细',
      breadcrumb: [
        {
          text: '销售退货单明细',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/salesreturnbounditem/warehouseList',
    name: 'apps-salesreturnbounditem-warehouseList',
    component: () => import('@/views/apps/salesreturnbounditem/WarehouseList.vue'),
    meta: {
      pageTitle: '退货通知单列表',
      breadcrumb: [
        {
          text: '退货通知单',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
]
