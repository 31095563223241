export default [
  {
    path: '/apps/companycredittemp/list',
    name: 'apps-companycredittemp-list',
    component: () => import('@/views/apps/companycredittemp/CompanyCreditTempList.vue'),
    meta: {
      pageTitle: '临时额度列表',
      breadcrumb: [
        {
          text: '临时额度',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/companycredittemp/edit',
    name: 'apps-companycredittemp-edit',
    component: () => import('@/views/apps/companycredittemp/CompanyCreditTempEdit.vue'),
    meta: {
      pageTitle: '临时额度信息',
      breadcrumb: [
        {
          text: '临时额度',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
