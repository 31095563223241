export default [
  {
    path: '/apps/priceguideoffline/list',
    name: 'apps-priceguideoffline-list',
    component: () => import('@/views/apps/priceguideoffline/PriceGuideOfflineList.vue'),
    meta: {
      pageTitle: '线下指导价列表',
      breadcrumb: [
        {
          text: '线下指导价',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/priceguideoffline/edit',
    name: 'apps-priceguideoffline-edit',
    component: () => import('@/views/apps/priceguideoffline/PriceGuideOfflineEdit.vue'),
    meta: {
      pageTitle: '销售定价规则',
      breadcrumb: [
        {
          text: '销售定价规则',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/priceguideoffline/view',
    name: 'apps-priceguideoffline-view',
    component: () => import('@/views/apps/priceguideoffline/PriceGuideOfflineEdit.vue'),
    meta: {
      pageTitle: '线下指导价',
      breadcrumb: [
        {
          text: '线下指导价',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/priceguide/list',
    name: 'apps-priceguide-list',
    component: () => import('@/views/apps/priceguideoffline/PriceGuideList.vue'),
    meta: {
      pageTitle: '线下指导价列表',
      breadcrumb: [
        {
          text: '线下指导价',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
]
