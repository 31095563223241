export default [
  {
    path: '/apps/produceorder/list',
    name: 'apps-produceorder-list',
    component: () => import('@/views/apps/produceorder/ProduceOrderList.vue'),
    meta: {
      pageTitle: '非标生产单列表',
      breadcrumb: [
        {
          text: '非标生产单',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/produceorder/edit',
    name: 'apps-produceorder-edit',
    component: () => import('@/views/apps/produceorder/ProduceOrderEdit.vue'),
    meta: {
      pageTitle: '非标生产单信息',
      breadcrumb: [
        {
          text: '非标生产单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/produceorder/view',
    name: 'apps-produceorder-view',
    component: () => import('@/views/apps/produceorder/ProduceOrderEdit.vue'),
    meta: {
      pageTitle: '非标生产单信息',
      breadcrumb: [
        {
          text: '非标生产单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/produceorder/produce',
    name: 'apps-produceorder-produce',
    component: () => import('@/views/apps/produceorder/Produce.vue'),
    meta: {
      pageTitle: '非标生产单列表',
      breadcrumb: [
        {
          text: '非标生产单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/produceorder/produceedit',
    name: 'apps-produceorder-produceedit',
    component: () => import('@/views/apps/produceorder/ProduceEdit.vue'),
    meta: {
      pageTitle: '非标生产单信息',
      breadcrumb: [
        {
          text: '非标生产单',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },

]
