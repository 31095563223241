export default [
  {
    path: '/apps/company/channel/list',
    name: 'apps-company-channel-list',
    component: () => import('@/views/apps/company/CompanyList.vue'),
    meta: {
      pageTitle: '单位列表',
      breadcrumb: [
        {
          text: '单位',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/company/channel/edit',
    name: 'apps-company-channel-edit',
    component: () => import('@/views/apps/company/CompanyEdit.vue'),
    meta: {
      pageTitle: '渠道商信息',
      breadcrumb: [
        {
          text: '渠道商',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/finance/company/supplier/list',
    name: 'apps-finance-company-supplier-list',
    component: () => import('@/views/apps/company/finance-modal/CompanyList.vue'),
    meta: {
      pageTitle: '财务用供应商列表',
      breadcrumb: [
        {
          text: '供应商',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/company/supplier/list',
    name: 'apps-company-supplier-list',
    component: () => import('@/views/apps/company/CompanyList.vue'),
    meta: {
      pageTitle: '供应商列表',
      breadcrumb: [
        {
          text: '供应商',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/company/supplier/edit',
    name: 'apps-company-supplier-edit',
    component: () => import('@/views/apps/company/CompanyEdit.vue'),
    meta: {
      pageTitle: '供应商信息',
      breadcrumb: [
        {
          text: '供应商',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/company/branch/list',
    name: 'apps-company-branch-list',
    component: () => import('@/views/apps/company/CompanyList.vue'),
    meta: {
      pageTitle: '集团公司列表',
      breadcrumb: [
        {
          text: '集团公司',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/company/branch/edit',
    name: 'apps-company-branch-edit',
    component: () => import('@/views/apps/company/CompanyEdit.vue'),
    meta: {
      pageTitle: '集团公司信息',
      breadcrumb: [
        {
          text: '集团公司',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/company/warehouse/list',
    name: 'apps-company-warehouse-list',
    component: () => import('@/views/apps/company/CompanyList.vue'),
    meta: {
      pageTitle: '仓库方列表',
      breadcrumb: [
        {
          text: '仓库方',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/company/warehouse/edit',
    name: 'apps-company-warehouse-edit',
    component: () => import('@/views/apps/company/CompanyEdit.vue'),
    meta: {
      pageTitle: '仓库方信息',
      breadcrumb: [
        {
          text: '仓库方',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/company/logistics/list',
    name: 'apps-company-logistics-list',
    component: () => import('@/views/apps/company/CompanyList.vue'),
    meta: {
      pageTitle: '物流公司列表',
      breadcrumb: [
        {
          text: '物流公司',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/company/logistics/edit',
    name: 'apps-company-logistics-edit',
    component: () => import('@/views/apps/company/CompanyEdit.vue'),
    meta: {
      pageTitle: '物流公司信息',
      breadcrumb: [
        {
          text: '物流公司',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/company/load/list',
    name: 'apps-company-load-list',
    component: () => import('@/views/apps/company/CompanyList.vue'),
    meta: {
      pageTitle: '装卸队列表',
      breadcrumb: [
        {
          text: '装卸队',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/company/load/edit',
    name: 'apps-company-load-edit',
    component: () => import('@/views/apps/company/CompanyEdit.vue'),
    meta: {
      pageTitle: '装卸队信息',
      breadcrumb: [
        {
          text: '装卸队',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/company/view',
    name: 'apps-company-view',
    component: () => import('@/views/apps/company/CompanyEdit.vue'),
    meta: {
      pageTitle: '企业',
      breadcrumb: [
        {
          text: '企业',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
