export default [
  {
    path: '/apps/logisticscarriager/list',
    name: 'apps-logisticscarriager-list',
    component: () => import('@/views/apps/logisticscarriager/LogisticsCarriagerList.vue'),
    meta: {
      pageTitle: '承运信息管理列表',
      breadcrumb: [
        {
          text: '承运信息管理',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/logisticscarriager/edit',
    name: 'apps-logisticscarriager-edit',
    component: () => import('@/views/apps/logisticscarriager/LogisticsCarriagerEdit.vue'),
    meta: {
      pageTitle: '承运信息管理信息',
      breadcrumb: [
        {
          text: '承运信息管理',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
