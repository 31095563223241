export default [
  {
    path: '/apps/companycredit/list',
    name: 'apps-companycredit-list',
    component: () => import('@/views/apps/companycredit/CompanyCreditList.vue'),
    meta: {
      pageTitle: '授信管理列表',
      breadcrumb: [
        {
          text: '授信管理',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/companycredit/edit',
    name: 'apps-companycredit-edit',
    component: () => import('@/views/apps/companycredit/CompanyCreditEdit.vue'),
    meta: {
      pageTitle: '授信管理信息',
      breadcrumb: [
        {
          text: '授信管理',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
