export default [
  {
    path: '/apps/purchase/order/cycle/list',
    name: 'apps-purchase-order-cycle-list',
    component: () => import('@/views/apps/purchaseordercycle/PurchaseOrderCycleList.vue'),
    meta: {
      pageTitle: '订单周期列表',
      breadcrumb: [
        {
          text: '订单周期',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
]
