export default [
  {
    path: '/apps/gridhousearap/list',
    name: 'apps-gridhousearap-list',
    component: () => import('@/views/apps/gridhousearap/GridhouseARAPList.vue'),
    meta: {
      pageTitle: '网格仓应收应付列表',
      breadcrumb: [
        {
          text: '应收应付',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
]
