export default [
  {
    path: '/apps/punishorderdeal/list',
    name: 'apps-punishorderdeal-list',
    component: () => import('@/views/apps/punishorderdeal/PunishOrderDealList.vue'),
    meta: {
      pageTitle: '罚款处理列表',
      breadcrumb: [
        {
          text: '罚款处理',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
]
