export default [
  {
    path: '/apps/offlinesalesorderitem/list',
    name: 'apps-offlinesalesorderitem-list',
    component: () => import('@/views/apps/offlinesalesorderitem/SalesOrderItemList.vue'),
    meta: {
      pageTitle: '列表',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/offlinesalesorderitem/edit',
    name: 'apps-offlinesalesorderitem-edit',
    component: () => import('@/views/apps/offlinesalesorderitem/SalesOrderItemEdit.vue'),
    meta: {
      pageTitle: '信息',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/offlinesalesorderitem/view',
    name: 'apps-offlinesalesorderitem-view',
    component: () => import('@/views/apps/offlinesalesorderitem/SalesOrderItemEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
