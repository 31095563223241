export default [
  {
    path: '/apps/purchaserefund/list',
    name: 'apps-purchaserefund-list',
    component: () => import('@/views/apps/purchaserefund/PurchaseRefundList.vue'),
    meta: {
      pageTitle: '采购退款列表',
      breadcrumb: [
        {
          text: '采购退款',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchaserefund/edit',
    name: 'apps-purchaserefund-edit',
    component: () => import('@/views/apps/purchaserefund/PurchaseRefundEdit.vue'),
    meta: {
      pageTitle: '采购退款信息',
      breadcrumb: [
        {
          text: '采购退款',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchaserefund/view',
    name: 'apps-purchaserefund-view',
    component: () => import('@/views/apps/purchaserefund/PurchaseRefundEdit.vue'),
    meta: {
      pageTitle: '采购退款',
      breadcrumb: [
        {
          text: '采购退款',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
