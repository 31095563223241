export default [
  {
    path: '/apps/punishorder/list',
    name: 'apps-punishorder-list',
    component: () => import('@/views/apps/punishorder/PunishOrderList.vue'),
    meta: {
      pageTitle: '罚款管理列表',
      breadcrumb: [
        {
          text: '罚款管理',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/punishorder/edit',
    name: 'apps-punishorder-edit',
    component: () => import('@/views/apps/punishorder/PunishOrderEdit.vue'),
    meta: {
      pageTitle: '罚款管理信息',
      breadcrumb: [
        {
          text: '罚款管理',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
