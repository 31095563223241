export default [
  {
    path: '/apps/user/list',
    name: 'apps-user-list',
    component: () => import('@/views/apps/user/UserList.vue'),
    meta: {
      pageTitle: '用户列表',
      breadcrumb: [
        {
          text: '用户',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },

  {
    path: '/apps/user/edit',
    name: 'apps-user-edit',
    component: () => import('@/views/apps/user/UserEdit.vue'),
    meta: {
      pageTitle: '用户信息',
      breadcrumb: [
        {
          text: '用户',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/user/view',
    name: 'apps-user-view',
    component: () => import('@/views/apps/user/UserEdit.vue'),
    meta: {
      pageTitle: '用户',
      breadcrumb: [
        {
          text: '用户',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/user/setting',
    name: 'pages-user-setting',
    component: () => import('@/views/apps/user/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'Account Settings',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Account Settings',
          active: true,
        },
      ],
    },
  },
]
