export default [
  {
    path: '/apps/inventoryorderdetail/list',
    name: 'apps-inventoryorderdetail-list',
    component: () => import('@/views/apps/inventoryorderdetail/InventoryOrderDetailList.vue'),
    meta: {
      pageTitle: '库存盘点明细列表',
      breadcrumb: [
        {
          text: '库存盘点明细',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inventoryorderdetail/edit',
    name: 'apps-inventoryorderdetail-edit',
    component: () => import('@/views/apps/inventoryorderdetail/InventoryOrderDetailEdit.vue'),
    meta: {
      pageTitle: '库存盘点明细信息',
      breadcrumb: [
        {
          text: '库存盘点明细',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inventoryorderdetail/view',
    name: 'apps-inventoryorderdetail-view',
    component: () => import('@/views/apps/inventoryorderdetail/InventoryOrderDetailEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
