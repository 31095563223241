export default [
  {
    path: '/apps/purchaseoutbounditem/list',
    name: 'apps-purchaseoutbounditem-list',
    component: () => import('@/views/apps/purchaseoutbounditem/PurchaseOutboundItemList.vue'),
    meta: {
      pageTitle: '列表',
      breadcrumb: [
        {
          text: '采购退料出库',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchaseoutbounditem/edit',
    name: 'apps-purchaseoutbounditem-edit',
    component: () => import('@/views/apps/purchaseoutbounditem/PurchaseOutboundItemEdit.vue'),
    meta: {
      pageTitle: '信息',
      breadcrumb: [
        {
          text: '采购退料出库',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchaseoutbounditem/view',
    name: 'apps-purchaseoutbounditem-view',
    component: () => import('@/views/apps/purchaseoutbounditem/PurchaseOutboundItemEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
