import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    list(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/attachment/list', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/attachment/search', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/attachment/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/attachment/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/attachment/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/attachment/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    select(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/attachment/select', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    load(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/attachment/load', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    upload(ctx, params) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: { "Content-Type": "multipart/form-data" }
        }
        axios
            .post('/api/attachment/upload', params, config)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
