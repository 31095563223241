export default [
  {
    path: '/apps/cargoforce/list',
    name: 'apps-cargoforce-list',
    component: () => import('@/views/apps/cargoforce/CargoForceList.vue'),
    meta: {
      pageTitle: '货权转移列表',
      breadcrumb: [
        {
          text: '货权转移',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
]
