export default [
  {
    path: '/apps/financeresourcerelation/list',
    name: 'apps-financeresourcerelation-list',
    component: () => import('@/views/apps/financeresourcerelation/FinanceResourceRelationList.vue'),
    meta: {
      pageTitle: '账号关联列表',
      breadcrumb: [
        {
          text: '账号关联列表',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/financeresourcerelation/edit',
    name: 'apps-financeresourcerelation-edit',
    component: () => import('@/views/apps/financeresourcerelation/FinanceResourceRelationEdit.vue'),
    meta: {
      pageTitle: '信息',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/financeresourcerelation/view',
    name: 'apps-financeresourcerelation-view',
    component: () => import('@/views/apps/financeresourcerelation/FinanceResourceRelationEdit.vue'),
    meta: {
      pageTitle: '资金流水',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
