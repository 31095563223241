export default [
  {
    path: '/apps/warehousestatementrule/list',
    name: 'apps-warehousestatementrule-list',
    component: () => import('@/views/apps/warehousestatementrule/WarehouseStatementRuleList'),
    meta: {
      pageTitle: '列表',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/warehousestatementrule/edit',
    name: 'apps-warehousestatementrule-edit',
    component: () => import('@/views/apps/warehousestatementrule/WarehouseStatementRuleEdit.vue'),
    meta: {
      pageTitle: '信息',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/warehousestatementrule/view',
    name: 'apps-warehousestatementrule-view',
    component: () => import('@/views/apps/warehousestatementrule/WarehouseStatementRuleEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
