export default [
  {
    path: '/apps/purchaseorderchangecost/list',
    name: 'apps-purchaseorderchangecost-list',
    component: () => import('@/views/apps/purchaseorderchangecost/PurchaseOrderChangecostList.vue'),
    meta: {
      pageTitle: '采购订单改价列表',
      breadcrumb: [
        {
          text: '采购订单改价',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchaseorderchangecost/edit',
    name: 'apps-purchaseorderchangecost-edit',
    component: () => import('@/views/apps/purchaseorderchangecost/PurchaseOrderChangecostEdit.vue'),
    meta: {
      pageTitle: '采购订单改价信息',
      breadcrumb: [
        {
          text: '采购订单改价',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/purchaseorderchangecost/view',
    name: 'apps-purchaseorderchangecost-view',
    component: () => import('@/views/apps/purchaseorderchangecost/PurchaseOrderChangecostEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
