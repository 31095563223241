export default [
  {
    path: '/apps/pricefixedruleonlinenormal/list',
    name: 'apps-pricefixedruleonlinenormal-list',
    component: () => import('@/views/apps/pricefixedruleonlinenormal/PriceFixedRuleOnlineNormalList.vue'),
    meta: {
      pageTitle: '线下定价规则 常规品列表',
      breadcrumb: [
        {
          text: '线下定价规则 常规品',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/pricefixedruleonlinenormal/edit',
    name: 'apps-pricefixedruleonlinenormal-edit',
    component: () => import('@/views/apps/pricefixedruleonlinenormal/PriceFixedRuleOnlineNormalEdit.vue'),
    meta: {
      pageTitle: '线下定价规则 常规品信息',
      breadcrumb: [
        {
          text: '线下定价规则 常规品',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/pricefixedruleonlinenormal/view',
    name: 'apps-pricefixedruleonlinenormal-view',
    component: () => import('@/views/apps/pricefixedruleonlinenormal/PriceFixedRuleOnlineNormalEdit.vue'),
    meta: {
      pageTitle: '线下定价规则 常规品',
      breadcrumb: [
        {
          text: '线下定价规则 常规品',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
]
