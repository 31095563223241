export default [
  {
    path: '/apps/store/list',
    name: 'apps-store-list',
    component: () => import('@/views/apps/store/StoreList.vue'),
    meta: {
      pageTitle: '商铺管理列表',
      breadcrumb: [
        {
          text: '商铺管理',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/store/edit',
    name: 'apps-store-edit',
    component: () => import('@/views/apps/store/StoreEdit.vue'),
    meta: {
      pageTitle: '商铺管理信息',
      breadcrumb: [
        {
          text: '商铺管理',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/store/view',
    name: 'apps-store-view',
    component: () => import('@/views/apps/store/StoreEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: '',
        },
        {
          text: '信息',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/store/listSubmit',
    name: 'apps-store-listSubmit',
    component: () => import('@/views/apps/store/store-wait/StoreList'),
    meta: {
      pageTitle: '待审核店铺列表',
      breadcrumb: [
        {
          text: '店铺',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
]
