export default [
  {
    path: '/apps/store/cash/out/list',
    name: 'apps-store-cash-out-list',
    component: () => import('@/views/apps/storecashout/StoreCashOutList.vue'),
    meta: {
      pageTitle: '提现记录列表',
      breadcrumb: [
        {
          text: '提现记录',
        },
        {
          text: '列表',
          active: true,
        },
      ],
    },
  },
]
